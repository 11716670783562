import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { FC } from 'react';
import { useAppSelector } from 'src/app/hooks';
import { userStateSelector } from 'src/app/user/UserStoreSelectors';

interface ModalJobFormRequestProps {
  isOpen: boolean;
  onClose: () => void;
  listingUid: string | null;
  listingName: string | null;
}

const ModalJobFormRequest: FC<ModalJobFormRequestProps> = ({ isOpen, onClose, listingName, listingUid }) => {
  const { userProfile } = useAppSelector(userStateSelector);

  if (!isOpen) return null;

  const validListingUid = listingUid || '';
  const validListingName = listingName || '';
  const formUrl = `https://form.jotform.com/242116198853158?user=${encodeURIComponent(
    userProfile.email
  )}&listingUid=${encodeURIComponent(validListingUid)}&listingName=${encodeURIComponent(validListingName)}`;

  return (
    <>
      <div
        onClick={onClose}
        className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
      >
        <div
          className="bg-white w-full rounded shadow-lg md:w-[50%]"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="p-4">
            <div className="w-full flex border-b px-2 pt-2 pb-6">
              <div className="w-11/12 gap-3">
                <h1 className="text-core-black font-medium text-xl p-0 m-0 whitespace-nowrap text-left">
                  Active listing request
                </h1>
              </div>
              <div className="w-1/12 flex justify-end">
                <button
                  onClick={onClose}
                  className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            </div>

            <div className="flex w-full">
              <iframe title="JotForm Form" src={formUrl} width="100%" height="600" style={{ border: 'none' }}></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalJobFormRequest;
