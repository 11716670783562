import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, LinearScale, CategoryScale);

interface BarChartProps {
  policies: any;
}

const BarChart: React.FC<BarChartProps> = ({ policies }) => {
  const [topOpenLine, setTopOpenLine] = useState(false);

  const groupPoliciesByLOB = (policies: any) => {
    const groupedPolicies: { [key: string]: { line_of_business: string; num_of_claims: number; premium: number }[] } = {
      personal: [],
      commercial: [],
    };

    for (const policy of policies) {
      const lob = policy.line_of_business.toLowerCase();
      const group = lob.includes('personal') ? 'personal' : 'commercial';

      if (!groupedPolicies[group]) {
        groupedPolicies[group] = [];
      }

      const existingPolicy = groupedPolicies[group].find((p) => p.line_of_business === policy.line_of_business);

      if (existingPolicy) {
        existingPolicy.num_of_claims += policy.num_of_claims;
        existingPolicy.premium += policy.premium;
      } else {
        groupedPolicies[group].push({
          line_of_business: policy.line_of_business,
          num_of_claims: policy.num_of_claims,
          premium: policy.premium,
        });
      }
    }

    return groupedPolicies;
  };

  const groupedPolicies = groupPoliciesByLOB(policies);

  const formatLabel = (policy: any) => {
    return policy.line_of_business.toUpperCase();
  };

  // Sort grouped policies by premium for personal and commercial groups
  groupedPolicies.personal.sort((a, b) => b.premium - a.premium);
  groupedPolicies.commercial.sort((a, b) => b.premium - a.premium);

  const barChartData = {
    labels: groupedPolicies.personal.map(formatLabel).concat(groupedPolicies.commercial.map(formatLabel)),
    datasets: [
      {
        label: 'Personal',
        data: groupedPolicies.personal.map((policy) => policy.num_of_claims),
        backgroundColor: '#276EF1',
        borderRadius: 4,
        minBarLength: 6,
      },
      {
        label: 'Commercial',
        data: groupedPolicies.commercial.map((policy) => policy.num_of_claims),
        backgroundColor: '#0046C8',
        borderRadius: 4,
        minBarLength: 6,
      },
    ],
  };

  const handleOpenTopLine = () => {
    setTopOpenLine(!topOpenLine);
  };

  return (
    <div className="w-full bg-core-lightGrey rounded-lg p-6">
      <div className="w-full flex">
        <div className="text-left w-4/5 flex flex-col">
          <h1 className="p-0 m-0 font-medium text-core-black text-lg">Number of Customers by LOB</h1>
        </div>

        <div className="w-1/5 flex justify-end">
          <button className="bg-white rounded-md py-1 px-1.5 hover:shadow" onClick={handleOpenTopLine}>
            {topOpenLine ? (
              <ChevronUpIcon className="w-4 h-4 my-auto" />
            ) : (
              <ChevronDownIcon className="w-4 h-4 my-auto" />
            )}
          </button>
        </div>
      </div>

      {topOpenLine && (
        <div className="w-full">
          <div className="w-full flex my-4 gap-3">
            <div className="w-fit flex text-left gap-2">
              <div className="w-2 h-2 rounded-full my-auto bg-[#276EF1]"></div>
              <div className="text-gray-900 text-sm font-medium">Personal</div>
            </div>
            <div className="w-fit flex text-left gap-2">
              <div className="w-2 h-2 rounded-full my-auto bg-[#0046C8]"></div>
              <div className="text-gray-900 text-sm font-medium">Commercial</div>
            </div>
          </div>

          <div className="w-full">
            <div className="mt-4">
              <Bar
                data={barChartData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  indexAxis: 'y',
                  scales: {
                    y: {
                      position: 'left',
                      ticks: {
                        display: true,
                        color: '#5E5E5E',
                        padding: 4,
                      },
                      grid: {
                        display: false,
                      },
                    },
                    x: {
                      display: true,
                      position: 'top',
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BarChart;
