import React, { FC, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../shared/components/Breadcrumb/Breadcrumb';
import Tabs from '../shared/components/Tabs/Tabs';
import { LoadingIcon } from 'src/styles/assets/icons/Icons';
import { useHistory, useLocation } from 'react-router';
import { useGetUser } from 'src/hooks';
import Book from './book-tabs/Book';
import {
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EllipsisHorizontalIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import Slices from './book-tabs/Slices';
import { useGetSlicesGroup } from 'src/hooks/usePolicies';
import Skeleton from '../shared/components/Skeleton/Skeleton';
import { AddSlicesGroup } from 'src/api/policies';
import useAlert from '../shared/components/Toast/useAlert';
import ModalDeleteConfirmation from './book-tabs/components/ModalDeleteConfirmation';
import { SlicesGroup } from 'src/types/Policies';
import Listings from './book-tabs/Listings';
import Notifications from '../shared/components/Notifications/notifications';
import { useGetIntegrationsData } from 'src/hooks/useIntegrations';
import { useGetAnalytics } from 'src/hooks/useAnalytics';
import { formatLastSyncShort } from '../shared/utils/formatters';
import ModalDataPolicy from './book-tabs/components/ModalDataPolicy';
import ModalDeleteListing from './book-tabs/components/ModalDeleteListings';

export const BookManagement: FC<{ onShowAnalytics: (showAnalytics: boolean) => void }> = ({ onShowAnalytics }) => {
  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const { data: userResponse, isPending } = useGetUser();
  const [showOptions, setShowOptions] = useState(false);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const buttonContainerRefActions = useRef<HTMLDivElement>(null);
  const [showLayout, setShowLayout] = useState(false);
  const [continueButton, setContinueButton] = useState(false);
  const { data: slicesData, isPending: slicesPending, refetch } = useGetSlicesGroup();

  const [showActions, setShowActions] = useState(false);
  const [showSlicesListing, setShowSlicesListing] = useState(false);
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  const [selectedSlices, setSelectedSlices] = useState<string[]>([]);
  const [selectedSlice, setSelectedSlice] = useState<string>('');
  const { alerts, createToast } = useAlert();
  const { data: integrationData } = useGetIntegrationsData();

  const [sortedGroups, setSortedGroups] = useState<
    {
      groupType: string;
      uid: string;
      name: string;
      totalPremium: number;
      totalPolicies: number;
      totalCustomers: number;
      createdDate: string;
      lastModified: string;
    }[]
  >([]);
  const isMouseOverMenu = useState(false);
  const [disabledActionButton, setDisabledActionButton] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [action, setShowAction] = useState<string>('Delete' || '');
  const [typeData, setTypeData] = useState<string>('');
  const [filteredSlidesData, setFilteredSlidesData] = useState<string>();
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [showOptionsActions, setShowOptionsActions] = useState(false);
  const [tabActionsButton, setTabActionsButton] = useState<string>();
  const [isRefetchIntegration, setIsRefetchIntegration] = useState<boolean>(false);
  const [hasConflictList, sethasConflictList] = React.useState(0);
  const { data: dataAnalytics } = useGetAnalytics();
  const [listingCreate, setListingCreate] = useState<boolean>(false);

  useEffect(() => {
    const onBoardingRedirect = userResponse?.userProfile?.onboardingComplete ?? false;
    const currentUrl = location.pathname + location.hash;

    if (!onBoardingRedirect && currentUrl !== '/onboarding/role') {
      window.location.href = '/onboarding/role';
    }
  }, [userResponse, location.pathname, location.hash]);

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
      if (slicesData && slicesData.groups) {
        const sorted = [...slicesData.groups].sort((a, b) => a.name.localeCompare(b.name));
        setSortedGroups(sorted);
      }
    };
    fetchData();
  }, [refetch, slicesData]);

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const handleNumberChange = (newNumber: number) => {
    sethasConflictList(newNumber);
  };

  const handleTabClick = (index: number) => {
    const tab = tabs[index];
    if (tab) {
      setActiveTab(index);
      if (tab.labelUrl === 'analytics') {
        onShowAnalytics(true);
      } else {
        onShowAnalytics(false);
      }
    }
  };

  const handleOpenCreate = () => {
    setShowOptions(!showOptions);
  };

  const handleUpdateFilteredSlidesData = (data: SlicesGroup[]) => {
    if (data && data.length > 0 && data[0].groups && data[0].groups.length > 0) {
      const uid = data[0].groups[0].uid;
      setFilteredSlidesData(uid);
    } else {
      setFilteredSlidesData('');
    }
  };

  const handleDeleteSuccess = (success: boolean) => {
    setIsDeleteSuccess(success);
  };

  const handleUpdateSuccess = (successUpdate: boolean) => {
    setIsDeleteSuccess(successUpdate);
  };

  const closeModalConfirmation = () => {
    setIsModalOpen(false);
    setSelectedUid('');
  };

  const tabs = [
    {
      label: (
        <div className="flex items-center gap-2">
          My Book
          {integrationData &&
            integrationData.integration &&
            integrationData.integration.status === 'Enabled' &&
            integrationData.integration.last_sync === null && (
              <ArrowPathIcon className="text-core-black h-4 w-4 animate-spin" />
            )}
        </div>
      ),
      content: (
        <div>
          <Book
            integrationStatus={integrationData?.integration?.status ?? ''}
            showLayout={showLayout}
            integrationlastSync={integrationData?.integration?.last_sync ?? ''}
            continueButton={continueButton}
            setContinueButton={setContinueButton}
            setShowLayout={setShowLayout}
            onSelectPolicies={(selectedPolicies) => setSelectedPolicies(selectedPolicies)}
            onUpdateFilteredSlidesData={handleUpdateFilteredSlidesData}
            isDelete={isDeleteSuccess}
            onEmptyStateChange={(isEmpty) => {
              setShowOptionsActions(isEmpty);
            }}
            onSetIsDelete={(value) => handleUpdateSuccess(value)}
            refetchIntegration={isRefetchIntegration}
            setRefetchIntegration={setIsRefetchIntegration}
            onNumberChange={handleNumberChange}
          />
        </div>
      ),
      labelUrl: 'book',
    },
    {
      label: 'Slices',
      content: (
        <div>
          <Slices
            onSelectSlices={(selectedSlices) => setSelectedSlices(selectedSlices)}
            isDelete={isDeleteSuccess}
            isListingCreateAction={listingCreate}
            showLayout={showLayout}
            setShowLayout={setShowLayout}
            continueButton={continueButton}
            setContinueButton={setContinueButton}
          />
        </div>
      ),
      labelUrl: 'slices',
    },
    {
      label: 'Listings',
      content: (
        <div>
          <Listings
            onSelectListings={(selectedSlice) => setSelectedSlice(selectedSlice)}
            showLayout={showLayout}
            setShowLayout={setShowLayout}
            continueButton={continueButton}
            setContinueButton={setContinueButton}
          />
        </div>
      ),
      labelUrl: 'listings',
    },
    dataAnalytics &&
      dataAnalytics?.url !== '' && {
        label: 'Analytics',
        content: <div></div>,
        labelUrl: 'analytics',
      },
  ].filter(Boolean);

  const validTabs = tabs.filter(Boolean) as any[];

  useEffect(() => {
    const { pathname, hash } = window.location;
    if (pathname === '/book-management' && hash === '') {
      history.push('/book-management#book');
    }
  }, []);

  useEffect(() => {
    const currentLabelUrl = location.hash.replace('#', '');
    const foundTabIndex = tabs.findIndex((tab) => tab && tab.labelUrl === currentLabelUrl);
    setTabActionsButton(currentLabelUrl);

    if (foundTabIndex !== -1) {
      setActiveTab(foundTabIndex);
    }
  }, [location.hash, tabs]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (buttonContainerRef.current && !buttonContainerRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonContainerRef.current]);

  const handleClickOutsideActions = (event: any) => {
    if (buttonContainerRefActions.current && !buttonContainerRefActions.current.contains(event.target)) {
      setShowActions(false);
      setShowSlicesListing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideActions);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideActions);
    };
  }, []);

  const handleCreateSlice = () => {
    const currentUrl = location.pathname + location.hash;
    if (currentUrl !== '/book-management#book') {
      history.push('/book-management#book');
    }
    setShowLayout(true);
    setContinueButton(true);
  };

  const handleCreateListing = () => {
    const currentUrl = location.pathname + location.hash;
    if (currentUrl !== '/book-management#slices') {
      history.push('/book-management#slices');
    }
    setShowLayout(true);
    setContinueButton(true);
    setListingCreate(true);
  };

  const handleShowSlices = () => {
    setShowSlicesListing(!showSlicesListing);
  };

  const handleOnMouseShowSlices = () => {
    setShowSlicesListing(true);
  };

  const handleLeaveMouseShowSlices = () => {
    if (!isMouseOverMenu) {
      setShowSlicesListing(false);
      setShowSlicesListing(false);
    }
  };

  const handleDelete = () => {
    closeModalConfirmation();
    setSelectedUid('');
    createToast({ text: 'Slice deleted', variant: 'success' });
    window.location.reload();
  };

  const handleAssignToSlice = async (sliceUid: string, event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    try {
      await AddSlicesGroup(sliceUid, selectedPolicies);
      createToast({ text: 'Policies added successfully', variant: 'success' });
      localStorage.removeItem('selectedItems');
      setShowSlicesListing(false);
      setShowActions(false);
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Invalid action please try again.',
        variant: 'danger',
      });
    }
  };

  const handleOpenModalConfirm = (action: string, typeData: string) => {
    setShowAction(action);
    setTypeData(typeData);
    setShowDeleteModal(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUid, setSelectedUid] = useState<string | null>(null);

  const openModalDelteListingAction = (uid: string) => {
    setSelectedUid(uid);
    setIsModalOpen(true);
  };

  const [openModalDataPolicy, setOpenModalDataPolicy] = useState(false);

  const handleOpenModalDataPolicy = () => {
    setOpenModalDataPolicy(!openModalDataPolicy);
    refetch();
  };

  useEffect(() => {
    const isSelectedPoliciesEmpty = selectedPolicies.length === 0;
    const isTabIsListing = tabActionsButton === 'listings';
    const isSelectedSlicesEmpty = selectedSlices.length === 0;
    const isSelectedSliceEmpty = !selectedSlice;

    const shouldDisableActionButton =
      (isSelectedPoliciesEmpty && isSelectedSlicesEmpty && isSelectedSliceEmpty) || isTabIsListing;

    setDisabledActionButton(shouldDisableActionButton);
  }, [sortedGroups, selectedPolicies, selectedSlices, selectedSlice, tabActionsButton]);

  useEffect(() => {
    const isSelectedPoliciesEmpty = selectedPolicies.length === 0;
    const isSelectedSliceNonEmpty = !!selectedSlice;

    const shouldDisableActionButton = isSelectedPoliciesEmpty && !isSelectedSliceNonEmpty;

    setDisabledActionButton(shouldDisableActionButton);
  }, [selectedPolicies, selectedSlice]);

  if (isPending) {
    return (
      <div className="flex items-center justify-center absolute inset-0">
        <LoadingIcon className="w-12 h-12 text-gray-200 animate-spin fill-yellow-600" />
      </div>
    );
  }
  return (
    <div className="p-3 w-full">
      {integrationData &&
        integrationData.integration &&
        integrationData.integration.status === 'Enabled' &&
        integrationData.integration.last_sync === null && (
          <div className="w-full mb-2">
            <Notifications text="Book upload in progress We'll notify you when your upload is complete" type="info" />
          </div>
        )}

      {hasConflictList > 0 && (
        <div className="w-full">
          <Notifications
            text="<strong>Confilcts found</strong> Your most recent sync needs review"
            type="warning"
            action="review"
          />
        </div>
      )}

      <Breadcrumb title="Book Management" />

      <div className="bg-core-white rounded-lg w-full px-6 pb-0.5 pt-6 space-y-4 sm:space-y-0">
        <div className="w-full flex justify-end item-center gap-3 -mb-14 md:-mb-10">
          {integrationData &&
            integrationData.integration &&
            integrationData.integration.status === 'Enabled' &&
            integrationData.integration.last_sync !== null && (
              <p className="p-0 mx-0 text-gray-500 text-sm my-auto md:block hidden">
                Last sync {formatLastSyncShort(integrationData?.integration.last_sync)}
              </p>
            )}
          {/* <div className="item-center my-auto">
            <p className="p-0 m-0 text-gray-600 text-xs">Last synced on 2/20/24</p>
          </div>
          <div>
            <button className="p-2 rounded-[4px] border bg-white">
              <ArrowPathIcon className="h-5 w-5 text-core-black" />
            </button>
          </div>*/}
          <div className="w-fit" ref={buttonContainerRefActions}>
            <button
              className={`border rounded-[4px] md:py-2 py-2 px-2 md:px-3 ${
                disabledActionButton ? 'bg-gray-50 opacity-50 text-gray-400' : 'bg-white'
              }`}
              onClick={() => setShowActions(!showActions)}
              disabled={disabledActionButton}
            >
              <span className="flex gap-1 text-core-black text-sm font-medium">
                <div className="hidden md:flex gap-2 px-2">
                  Actions
                  {showActions ? (
                    <ChevronUpIcon className="h-3 w-3 text-core-black my-auto" />
                  ) : (
                    <ChevronDownIcon className="h-3 w-3 text-core-black my-auto" />
                  )}
                </div>

                <div className="flex md:hidden">
                  <EllipsisHorizontalIcon className="h-6 w-6 text-core-black m-auto" />
                </div>
              </span>
            </button>

            {showActions && (
              <div className="bg-white rounded border-1 border-blue-600 shadow absolute -ml-20 md:ml-0 mt-2 z-30">
                {tabActionsButton === 'book' && (
                  <ul className="w-[195px] p-2 m-0 whitespace-nowrap text-sm font-medium text-left">
                    {showOptionsActions ? (
                      <>
                        {/* <li className="p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in">
                        Unassign all
                      </li> */}
                        {!disabledActionButton && (
                          <>
                            <li
                              className="p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in flex"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleShowSlices();
                                handleOnMouseShowSlices();
                              }}
                              onMouseEnter={handleOnMouseShowSlices}
                              onMouseLeave={handleLeaveMouseShowSlices}
                            >
                              <span className="flex w-full">Assign to slice</span>
                              <ChevronRightIcon className="h-3 w-3 flex justify-end my-auto" />
                            </li>
                            <li
                              className="text-red-500 p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in"
                              onClick={() => handleOpenModalConfirm('delete', 'policy')}
                            >
                              Delete
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      <li
                        className="p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in flex"
                        onClick={() => handleOpenModalConfirm('remove', 'policy')}
                      >
                        Remove from Slice
                      </li>
                    )}
                  </ul>
                )}

                {tabActionsButton === 'slices' && (
                  <ul className="w-[195px] p-2 m-0 whitespace-nowrap text-sm font-medium text-left">
                    {showOptionsActions && (
                      <>
                        <li
                          className="text-red-500 p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in"
                          onClick={() => handleOpenModalConfirm('delete', 'slices')}
                        >
                          Delete
                        </li>
                      </>
                    )}
                  </ul>
                )}

                {tabActionsButton === 'listings' && (
                  <ul className="w-[195px] p-2 m-0 whitespace-nowrap text-sm font-medium text-left">
                    {selectedSlice.length && (
                      <li
                        className="text-red-500 p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in"
                        onClick={() => openModalDelteListingAction(selectedSlice)}
                      >
                        Delete
                      </li>
                    )}
                  </ul>
                )}
              </div>
            )}

            {showSlicesListing && (
              <div
                className="bg-white rounded border-1 border-blue-600 shadow absolute mt-6 md:mt-3 right-[200px] md:right-[200px] w-[200px] z-30"
                onMouseEnter={handleOnMouseShowSlices}
                onMouseLeave={handleLeaveMouseShowSlices}
              >
                {slicesPending ? (
                  <>
                    <Skeleton type="list-simple" />
                  </>
                ) : (
                  <>
                    {sortedGroups.length > 0 ? (
                      <ul className="w-[195px] p-2 m-0 whitespace-nowrap text-sm font-medium text-left max-h-[200px] h-fit overflow-x-auto">
                        {sortedGroups.map((slice) => (
                          <li
                            key={slice.uid}
                            className="p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in"
                            onClick={(event) => handleAssignToSlice(slice.uid, event)}
                          >
                            <p className="p-0 m-0 w-full justify-start flex truncate">{slice.name}</p>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="px-2 py-2.5 text-left">Assign to Slice</div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className="w-fit" ref={buttonContainerRef}>
            <button
              className={`rounded-[4px] border-1 border-core-yellow bg-core-yellow  md:py-2 py-2 px-2 md:px-3  ${
                showLayout ? 'opacity-50 bg-gray-50 text-gray-400 border-gray-200 cursor-not-allowed' : ''
              }`}
              onClick={(event) => {
                event.stopPropagation();
                handleOpenCreate();
              }}
              disabled={showLayout}
            >
              <span className="flex gap-1 text-core-black text-sm font-medium items-center">
                <div className="hidden md:flex gap-2 px-2">
                  Create
                  {showOptions ? (
                    <ChevronUpIcon className="h-3 w-3 text-core-black my-auto" />
                  ) : (
                    <ChevronDownIcon className="h-3 w-3 text-core-black my-auto" />
                  )}
                </div>

                <div className="flex md:hidden">
                  <PlusIcon className="h-6 w-6 text-core-black m-auto" />
                </div>
              </span>
            </button>

            {showOptions && (
              <div className="bg-white rounded border-1 border-blue-600 absolute shadow right-10 mt-2">
                <ul className="w-[190px] p-2 m-0 whitespace-nowrap text-sm font-medium text-left">
                  <li
                    className="p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in"
                    onClick={() => {
                      handleOpenModalDataPolicy();
                    }}
                  >
                    <span className="flex w-full">New Policy</span>
                  </li>
                  <li
                    className="p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in"
                    onClick={() => {
                      handleCreateSlice();
                      setShowOptions(false);
                    }}
                  >
                    New Slice
                  </li>

                  <li
                    className="p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in"
                    onClick={() => {
                      handleCreateListing();
                      setShowOptions(false);
                    }}
                  >
                    New Listing
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        {alerts}

        <Tabs tabs={validTabs} activeTab={activeTab} onTabClick={handleTabClick} variant={false} />

        {showDeleteModal && (
          <ModalDeleteConfirmation
            isOpen={showDeleteModal}
            groupUid={filteredSlidesData ?? ''}
            onClose={closeModal}
            selectedPolicies={selectedPolicies}
            selectedSlices={selectedSlices}
            onSuccess={handleDeleteSuccess}
            action={action}
            typeData={typeData}
          />
        )}

        {isModalOpen && selectedUid && (
          <ModalDeleteListing uid={selectedUid} onClose={closeModalConfirmation} onDelete={handleDelete} />
        )}

        {openModalDataPolicy && <ModalDataPolicy isOpen={openModalDataPolicy} onClose={handleOpenModalDataPolicy} />}
      </div>
    </div>
  );
};

export default BookManagement;
