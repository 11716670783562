import React, { ReactElement, FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useGetSubscription, useGetUser } from 'src/hooks';
import { LoadingIcon } from 'src/styles/assets/icons/Icons';
import Breadcrumb from '../shared/components/Breadcrumb/Breadcrumb';
import Tabs from '../shared/components/Tabs/Tabs';
import YourMatches from './home-tabs/list-view/YourMatches';
import Saved from './home-tabs/list-view/Saved';
import AllListings from './home-tabs/list-view/AllListings';

export const Home: FC = (): ReactElement => {
  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const { data: userResponse, isPending } = useGetUser();
  const { data: subscriptionResponse } = useGetSubscription();

  const isEnterprise = subscriptionResponse?.userSubscription === 'Enterprise';

  useEffect(() => {
    const onBoardingRedirect = userResponse?.userProfile?.onboardingComplete ?? false;
    if (!onBoardingRedirect) {
      window.location.href = '/onboarding/role';
    }
  }, [userResponse]);

  const handleTabClick = (index: number) => {
    const labelUrl = tabs[index].labelUrl;
    history.push(`#${labelUrl}`);
    setActiveTab(index);
  };

  const tabs = [
    {
      label: 'Your Matches',
      content: (
        <div>
          <YourMatches />
        </div>
      ),
      labelUrl: 'your-matches',
    },
    {
      label: 'Saved',
      content: (
        <div>
          <Saved />
        </div>
      ),
      labelUrl: 'saved',
    },
    {
      label: 'All Listings',
      content: (
        <div>
          <AllListings />
        </div>
      ),
      labelUrl: 'all-listings',
    },
  ];

  useEffect(() => {
    const currentLabelUrl = location.hash.replace('#', '');
    const foundTabIndex = tabs.findIndex((tab) => tab.labelUrl === currentLabelUrl);

    if (foundTabIndex !== -1) {
      setActiveTab(foundTabIndex);
    }
  }, [location.hash, tabs]);

  if (isPending) {
    return (
      <div className="flex items-center justify-center absolute inset-0">
        <LoadingIcon className="w-12 h-12 text-gray-200 animate-spin fill-yellow-600" />
      </div>
    );
  }

  return (
    <div className="p-3 w-full">
      <Breadcrumb title="Marketplace" />

      <div className="bg-core-white rounded-lg w-full px-6 pb-6 pt-6 space-y-4 sm:space-y-0">
        {isEnterprise ? (
          <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} variant={false} />
        ) : (
          <YourMatches />
        )}
      </div>
    </div>
  );
};

export default Home;
