import React, { ChangeEvent, useEffect, useState } from 'react';
import badWordsList from './BardWordsLists';

interface TextareaComponentProps {
  height: string;
  labelInput?: string;
  placeholderText?: string;
  showCharacterCount: boolean;
  maxCharacters?: number;
  isRequiredInput?: boolean | false;
  value?: string;
  name?: string;
  variant?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

const TextareaComponent: React.FC<TextareaComponentProps> = ({
  height,
  labelInput,
  placeholderText,
  showCharacterCount,
  maxCharacters,
  isRequiredInput,
  value,
  name,
  variant,
  onChange,
}) => {
  const [text, setText] = useState<string | undefined>(value);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputText: string = event.target.value;
    const badWords: string[] = getBadWords(inputText);

    if (badWords.length > 0) {
      setErrorMessages([`These words may be offensive and are not allowed: ${badWords.join(', ')}`]);
    } else {
      setErrorMessages([]);
    }

    const sanitizedText = inputText.replace(/[\r\n]+/g, ' ').slice(0, maxCharacters);

    setText(sanitizedText);

    if (onChange) {
      onChange(event);
    }
  };

  const getBadWords = (inputText: string): string[] => {
    const lowercaseText: string = inputText.toLowerCase();
    return badWordsList.filter((word) => {
      const surroundedBySpaces = new RegExp(`\\b${word}\\b`, 'i');
      return surroundedBySpaces.test(lowercaseText);
    });
  };

  return (
    <div className="w-full h-fit mt-2">
      <label
        htmlFor="description"
        className="flex justify-start bg-white px-2 mx-2 -mb-2 w-fit relative text-neutral-700 text-xs font-semibold gap-1 uppercase"
      >
        {labelInput}
        {isRequiredInput && <span className="text-red-500">*</span>}
      </label>

      <textarea
        className={`w-full ${
          variant === 'gray'
            ? 'border-gray-400 placeholder:text-gray-400'
            : 'border-core-black placeholder:text-gray-700'
        } border-1 rounded-lg ${height} p-3`}
        onChange={handleInputChange}
        placeholder={placeholderText}
        id="description"
        name={name}
        value={text}
      />

      {showCharacterCount && maxCharacters && (
        <div className="w-full flex justify-end">
          <p>
            {text ? text.length : ''} / {maxCharacters}
          </p>
        </div>
      )}

      {errorMessages.length > 0 && (
        <div className="w-full flex justify-start mt-0">
          {errorMessages.map((errorMessage, index) => (
            <p key={index} style={{ color: 'red' }}>
              {`${errorMessage}`}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default TextareaComponent;
