import React, { ReactElement, FC, useState, useEffect, useRef } from 'react';
import {
  PersonalListValues,
  CarrierListingOptions,
  UsStatesOptions,
  CommercialListValues,
} from 'src/app/shared/ListingEnums';
import { FilterOptions } from 'src/types/domain/Filters';
import Pills from '../Pills/Pills';
import ModalFilter from './ModalFilter';
import { Slice } from 'src/types/domain/Slices';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useGetSlicesGroup } from 'src/hooks/usePolicies';
import Skeleton from '../Skeleton/Skeleton';

export interface PriceFilter {
  min: string;
  max: string;
}

type EnumType = { [key: string]: string };
type FilterType =
  | 'carrierFilters'
  | 'usStateFilters'
  | 'lineOfBusinessFilters'
  | 'premiumRangeFilters'
  | 'buyerLocationFilters'
  | 'insuranceTypeFilters'
  | 'sliceNameFilters';

function convertArrayToEnumType(arr: { code: string; description: string }[]): EnumType {
  const enumType: EnumType = {};
  arr.forEach((item) => {
    enumType[item.code] = item.description;
  });
  return enumType;
}

const commercialEnum: EnumType = convertArrayToEnumType(CommercialListValues);
const personalEnum: EnumType = convertArrayToEnumType(PersonalListValues);

interface ListingFiltersBarProps {
  listingType?: string | null;
  listingData?: Slice[];
  handleTotalPremiumChange?: (value: PriceFilter) => void;
  handleStateChange: (values: Array<string>) => void;
  handleBuyerLocationChange?: (values: Array<string>) => void;
  handleLineOfBusinessChange: (value: Array<string>) => void;
  handleCarrierChange: (value: Array<string>) => void;
  handleSliceNameFiltersChange?: (value: string) => void;
  activeButton?: string;
  onActiveButtonChange?: (buttonName: string) => void;
  onInsuranceTypeChange?: (value: string) => void;
  buyerLocation?: boolean;
  moreFilter?: boolean;
  sliceFilter?: boolean;
}

export const HomeListingFiltersBar: FC<ListingFiltersBarProps> = (props): ReactElement => {
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [stateChangeFiltersAppliedOnce, setStateChangeFiltersAppliedOnce] = useState(false);
  const [lineOfBusinessFiltersAppliedOnce, setLineOfBusinessFiltersAppliedOnce] = useState(false);
  const [carrierFiltersAppliedOnce, setCarrierFiltersAppliedOnce] = useState(false);
  const [premiumRangeFiltersAppliedOnce, setPremiumRangeFiltersAppliedOnce] = useState(false);
  const [insurangerFiltersAppliedOnce, setInsuranceTypesAppliedOnce] = useState(false);
  const [buyerLocationFiltersAppliedOnce, setbuyerLocationFiltersAppliedOnce] = useState(false);
  const [sliceNameFilterAppliedOnce, setSliceNameFilterOnce] = useState(false);

  const [carrierPills, setCarrierPills] = useState<string[]>([]);
  const [usStatePills, setUsStatePills] = useState<string[]>([]);
  const [buyerLocationPills, setbuyerLocationPills] = useState<string[]>([]);
  const [lineOfBusinessPills, setLineOfBusinessPills] = useState<string[]>([]);
  const [premiumRangePills, setPremiumRangePills] = useState({});
  const [insuranceTypePills, setInsuranceTypePills] = useState('');
  const [slicesNamePills, setSlicesNamePills] = useState('');

  const allListValues = [...CommercialListValues, ...PersonalListValues];
  const personalValues = [...PersonalListValues];
  const commercialValues = [...CommercialListValues];
  const [refreshDropdownComponent, setRefreshDropdownComponent] = useState(false);
  const [showOptionsSlice, setShowOptionsSlice] = useState(false);
  const { data: slicesData, isPending, refetch } = useGetSlicesGroup();
  const [selectedSlice, setSelectedSlice] = useState<string>('');
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  // const [activeButton] = useState('listView');

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (buttonContainerRef.current && !buttonContainerRef.current.contains(event.target)) {
        setShowOptionsSlice(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonContainerRef.current]);

  const determineListValues = (): string[] => {
    const sliceType = localStorage.getItem('insuranceTypeFilters');

    switch (sliceType) {
      case 'Personal':
        return personalValues.map((item) => item.description);
      case 'Commercial':
        return commercialValues.map((item) => item.description);
      default:
        return allListValues.map((item) => item.description);
    }
  };

  const allPills = [
    ...carrierPills.map((item) => ({ type: 'carrierFilters', value: item })),
    ...usStatePills.map((item) => ({ type: 'usStateFilters', value: item })),
    ...buyerLocationPills.map((item) => ({ type: 'buyerLocationFilters', value: `BL: ${item}` })),
    ...lineOfBusinessPills.map((item) => ({ type: 'lineOfBusinessFilters', value: item })),
    ...(slicesNamePills ? [{ type: 'sliceNameFilters', value: selectedSlice }] : []),
  ].sort((a, b) => {
    const typeOrder = [
      'sliceNameFilters',
      'carrierFilters',
      'usStateFilters',
      'buyerLocationFilters',
      'lineOfBusinessFilters',
    ];

    const typeIndexA = typeOrder.indexOf(a.type);
    const typeIndexB = typeOrder.indexOf(b.type);

    if (typeIndexA !== typeIndexB) {
      return typeIndexA - typeIndexB;
    }

    return (a.value || '').localeCompare(b.value || '');
  });

  const updateFilters = (filterType: FilterType, parsedFilters: any) => {
    const filterHandlers: { [key in FilterType]: ((items: any) => void) | undefined } = {
      carrierFilters: props.handleCarrierChange,
      usStateFilters: props.handleStateChange,
      buyerLocationFilters: props.handleBuyerLocationChange,
      lineOfBusinessFilters: props.handleLineOfBusinessChange,
      premiumRangeFilters: props.handleTotalPremiumChange,
      insuranceTypeFilters: props.onInsuranceTypeChange,
      sliceNameFilters: props.handleSliceNameFiltersChange,
    };

    const filterHandler = filterHandlers[filterType];

    if (filterHandler) {
      filterHandler(filterType === 'premiumRangeFilters' ? parsedFilters : parsedFilters);
    }

    switch (filterType) {
      case 'carrierFilters':
        setCarrierPills(parsedFilters);
        break;

      case 'usStateFilters':
        setUsStatePills(parsedFilters);
        break;

      case 'lineOfBusinessFilters':
        setLineOfBusinessPills(parsedFilters);
        break;

      case 'buyerLocationFilters':
        setbuyerLocationPills(parsedFilters);
        break;

      case 'premiumRangeFilters':
        setPremiumRangePills(parsedFilters);
        break;

      case 'insuranceTypeFilters':
        setInsuranceTypePills(parsedFilters);
        break;

      case 'sliceNameFilters':
        setSlicesNamePills(parsedFilters);
        break;
    }
  };

  const handleAddFiltersLocalStorage = (filterType: FilterType) => {
    let parsedFilters;
    if (filterType === 'insuranceTypeFilters') {
      parsedFilters = localStorage.getItem(filterType) || '[]';
    } else if (filterType === 'sliceNameFilters') {
      parsedFilters = localStorage.getItem(filterType)?.replace(/"/g, '') || '';
    } else {
      const savedFilters = localStorage.getItem(filterType) || '[]';
      parsedFilters = JSON.parse(savedFilters);
    }

    updateFilters(filterType, parsedFilters);
  };

  const handleOpenFilterSlice = () => {
    refetch();
    setShowOptionsSlice(!showOptionsSlice);
  };

  useEffect(() => {
    // slice name group filter
    const savedSliceNameFilters = localStorage.getItem('sliceNameFilters');
    if (savedSliceNameFilters) {
      const parsedFiltersSliceName = JSON.parse(savedSliceNameFilters);
      if (props.handleSliceNameFiltersChange) {
        props.handleSliceNameFiltersChange(parsedFiltersSliceName);
        setSliceNameFilterOnce(true);
        setSelectedSlice(parsedFiltersSliceName);
        setSlicesNamePills(parsedFiltersSliceName);
      }
    }

    // Insurange type Filters
    const savedInsurangeTypeFilters = localStorage.getItem('insurangeTypeFilters');
    if (savedInsurangeTypeFilters) {
      const parsedFiltersInsurangeType = JSON.parse(savedInsurangeTypeFilters);
      if (props.onInsuranceTypeChange) {
        props.onInsuranceTypeChange(parsedFiltersInsurangeType);
        setInsuranceTypesAppliedOnce(true);
      }
    }

    // Total Premium Filters
    const savedTotalPremiumFilters = localStorage.getItem('premiumRangeFilters');
    if (savedTotalPremiumFilters) {
      const parsedFilters = JSON.parse(savedTotalPremiumFilters);
      if (props.handleTotalPremiumChange) {
        props.handleTotalPremiumChange(parsedFilters);
      }
      setPremiumRangeFiltersAppliedOnce(true);
    }

    // UsState Filters
    const savedUsStateFilters = localStorage.getItem('usStateFilters');
    if (savedUsStateFilters) {
      const parsedFiltersState = JSON.parse(savedUsStateFilters);
      props.handleStateChange(parsedFiltersState);
      setUsStatePills(parsedFiltersState);
      setStateChangeFiltersAppliedOnce(true);
    }

    // Buyer Location Filters
    const savedBuyerLocationFilters = localStorage.getItem('buyerLocationFilters');
    if (savedBuyerLocationFilters) {
      const parsedFiltersBuyerLocation = JSON.parse(savedBuyerLocationFilters);
      if (props.handleBuyerLocationChange) {
        props.handleBuyerLocationChange(parsedFiltersBuyerLocation);
      }
      setbuyerLocationPills(parsedFiltersBuyerLocation);
      setbuyerLocationFiltersAppliedOnce(true);
    }

    // Line of Business Filters
    const savedLineOfBusinessFilters = localStorage.getItem('lineOfBusinessFilters');
    if (savedLineOfBusinessFilters) {
      const parsedLineOfBusinessFilters = JSON.parse(savedLineOfBusinessFilters);
      props.handleLineOfBusinessChange(parsedLineOfBusinessFilters);
      setLineOfBusinessPills(parsedLineOfBusinessFilters);
      setLineOfBusinessFiltersAppliedOnce(true);
    }

    // Carrier Filters
    const savedCarrierFilters = localStorage.getItem('carrierFilters');
    if (savedCarrierFilters) {
      const parsedCarrierFilters = JSON.parse(savedCarrierFilters);
      props.handleCarrierChange(parsedCarrierFilters);
      setCarrierPills(parsedCarrierFilters);
      setCarrierFiltersAppliedOnce(true);
    }
  }, [
    stateChangeFiltersAppliedOnce,
    lineOfBusinessFiltersAppliedOnce,
    carrierFiltersAppliedOnce,
    premiumRangeFiltersAppliedOnce,
    insurangerFiltersAppliedOnce,
    buyerLocationFiltersAppliedOnce,
    sliceNameFilterAppliedOnce,
  ]);

  const renderCustomSelectOptions = (listingsEnumObj: EnumType, showCode = false): FilterOptions[] => {
    const entries = Object.entries(listingsEnumObj);
    const listValues = determineListValues();

    return entries.map(([value, label]) => {
      const item = allListValues.find((c) => c.code === value);

      if (item && item.description && showCode) {
        return {
          label: `${value} | ${label}`,
          value: listValues.includes(item.description) ? item.description : undefined,
        };
      } else {
        return {
          label,
          value: listValues.includes(label) ? label : undefined,
        };
      }
    });
  };

  const renderAllOptions = (): FilterOptions[] => {
    return renderCustomSelectOptions({ ...commercialEnum, ...personalEnum }, true);
  };

  const customSelectFilterOptions = {
    type: renderAllOptions(),
    carrier: renderCustomSelectOptions(CarrierListingOptions, true),
    usState: renderCustomSelectOptions(UsStatesOptions),
    buyerLocation: renderCustomSelectOptions(UsStatesOptions),
    moreFilters: '',
  };

  const handleFilterReset = (): void => {
    localStorage.removeItem('usStateFilters');
    localStorage.removeItem('lineOfBusinessFilters');
    localStorage.removeItem('carrierFilters');
    localStorage.removeItem('insuranceTypeFilters');
    localStorage.removeItem('premiumRangeFilters');
    localStorage.removeItem('buyerLocationFilters');
    localStorage.removeItem('sliceNameFilters');
    setSelectedSlice('');
    setCarrierPills([]);
    setUsStatePills([]);
    setbuyerLocationPills([]);
    setLineOfBusinessPills([]);
    setPremiumRangePills({});
    setInsuranceTypePills('');
    setSlicesNamePills('');
    props.handleCarrierChange([]);
    props.handleStateChange([]);
    props.handleLineOfBusinessChange([]);
    if (props.handleBuyerLocationChange) {
      props.handleBuyerLocationChange([]);
    }
    if (props.onInsuranceTypeChange) {
      props.onInsuranceTypeChange('');
    }
    if (typeof props.handleTotalPremiumChange === 'function') {
      props.handleTotalPremiumChange({ min: '', max: '' });
    }
    setIsFilterReset(!isFilterReset);
  };

  const removePill = (filterType: FilterType, value: string) => {
    try {
      if (filterType === 'sliceNameFilters') {
        localStorage.removeItem(filterType);
        updateFilters(filterType, '');
        setSelectedSlice('');
      } else {
        const localStorageKey = localStorage.getItem(filterType) || '[]';
        const updatedItems = JSON.parse(localStorageKey) as string[];

        const cleanedValue = filterType === 'buyerLocationFilters' ? value.replace('BL: ', '') : value;
        const index = updatedItems.indexOf(cleanedValue);
        if (index !== -1) {
          updatedItems.splice(index, 1);
        }

        localStorage.removeItem(filterType);
        localStorage.setItem(filterType, JSON.stringify(updatedItems));

        updateFilters(filterType, updatedItems);
      }

      setRefreshDropdownComponent(!refreshDropdownComponent);
    } catch (error) {
      console.error('Error handling filter removal:', error);
    }
  };

  const handleSelectSlice = (sliceName: string) => {
    setSelectedSlice(sliceName);
    localStorage.setItem('sliceNameFilters', `"${sliceName}"`);
    updateFilters('sliceNameFilters', sliceName);
    setShowOptionsSlice(false);
  };

  const isAnyFilterApplied =
    carrierPills.length > 0 ||
    usStatePills.length > 0 ||
    lineOfBusinessPills.length > 0 ||
    buyerLocationPills.length > 0 ||
    Object.keys(premiumRangePills).length > 0 ||
    insuranceTypePills.length > 0 ||
    slicesNamePills.length > 0;

  return (
    <>
      <div className="w-full flex overflow-x-auto pb-3 mb-2 md:!-mb-5 sm:pb-0">
        <div className="min-w-[500px] md:w-full flex justify-start gap-2">
          {props.sliceFilter && (
            <div className="w-40" ref={buttonContainerRef}>
              <button
                className="flex border w-full rounded border-black p-2 text-black font-medium text-sm text-left"
                onClick={handleOpenFilterSlice}
              >
                <p className="m-0 p-0 flex justify-start w-full whitespace-nowrap gap-0.5 truncate">
                  {selectedSlice || 'Slice'}
                </p>
                {showOptionsSlice ? (
                  <ChevronUpIcon className="w-4 h-4 my-auto flex justify-end" />
                ) : (
                  <ChevronDownIcon className="w-4 h-4 my-auto flex justify-end" />
                )}
              </button>

              {showOptionsSlice && (
                <div className="w-80 p-1 rounded-[3px] border-1 border-blue-500 mt-2 absolute bg-white">
                  {isPending ? (
                    <>
                      <Skeleton type="list-simple" />
                    </>
                  ) : (
                    <>
                      {slicesData && slicesData.groups && slicesData.groups.length > 0 ? (
                        <ul className="p-1 m-0 text-left text-sm bg-white max-h-[200px] z-30 relative h-fit overflow-x-auto space-y-2">
                          {slicesData &&
                            slicesData.groups.map((slice) => (
                              <li
                                key={slice.uid}
                                className={`hover:bg-gray-100 px-2 py-2.5 ${
                                  slice.name === selectedSlice ? 'bg-gray-100' : ''
                                } cursor-pointer transition ease-in rounded flex `}
                                onClick={() => {
                                  handleSelectSlice(slice.name);
                                  handleAddFiltersLocalStorage('sliceNameFilters');
                                }}
                              >
                                <p className="p-0 m-0 w-full justify-start flex">{slice.name}</p>

                                {slice.name === selectedSlice && (
                                  <CheckIcon className="h-4 w-4 text-blue-700 text-right my-auto flex justify-end" />
                                )}
                              </li>
                            ))}
                        </ul>
                      ) : (
                        <div className="px-2 py-2.5 text-left">No Slices found</div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="w-40">
            <ModalFilter
              title="Carriers"
              filterReset={isFilterReset}
              refreshDropdownComponent={refreshDropdownComponent}
              filterType={'carrierFilters'}
              options={customSelectFilterOptions.carrier}
              onRefreshComplete={() => {
                setRefreshDropdownComponent(false);
                setIsFilterReset(false);
              }}
              handleAddFiltersLocalStorage={() => handleAddFiltersLocalStorage('carrierFilters')}
            />
          </div>

          <div className="w-40">
            <ModalFilter
              title="States"
              filterReset={isFilterReset}
              refreshDropdownComponent={refreshDropdownComponent}
              filterType={'usStateFilters'}
              options={customSelectFilterOptions.usState}
              onRefreshComplete={() => {
                setRefreshDropdownComponent(false);
                setIsFilterReset(false);
              }}
              handleAddFiltersLocalStorage={() => handleAddFiltersLocalStorage('usStateFilters')}
            />
          </div>

          <div className="w-40">
            <ModalFilter
              title="Lines"
              filterReset={isFilterReset}
              refreshDropdownComponent={refreshDropdownComponent}
              filterType={'lineOfBusinessFilters'}
              options={customSelectFilterOptions.type}
              onRefreshComplete={() => {
                setRefreshDropdownComponent(false);
                setIsFilterReset(false);
              }}
              handleAddFiltersLocalStorage={() => handleAddFiltersLocalStorage('lineOfBusinessFilters')}
            />
          </div>

          {props.buyerLocation && (
            <div className="w-40">
              <ModalFilter
                title="Buyer Location"
                filterReset={isFilterReset}
                refreshDropdownComponent={refreshDropdownComponent}
                filterType={'buyerLocationFilters'}
                options={customSelectFilterOptions.buyerLocation}
                onRefreshComplete={() => {
                  setRefreshDropdownComponent(false);
                  setIsFilterReset(false);
                }}
                handleAddFiltersLocalStorage={() => handleAddFiltersLocalStorage('buyerLocationFilters')}
              />
            </div>
          )}

          {!props.buyerLocation && props.moreFilter && (
            <div className="w-40">
              <ModalFilter
                title="More Filters"
                filterReset={isFilterReset}
                refreshDropdownComponent={refreshDropdownComponent}
                filterType={'moreFilters'}
                onRefreshComplete={() => {
                  setRefreshDropdownComponent(false);
                  setIsFilterReset(false);
                }}
                handleAddFiltersLocalStorage={() => {
                  handleAddFiltersLocalStorage('premiumRangeFilters');
                  handleAddFiltersLocalStorage('insuranceTypeFilters');
                }}
              />
            </div>
          )}

          {isAnyFilterApplied && (
            <div className="font-bold sm:flex align-items-center uppercase hidden cursor-pointer whitespace-nowrap">
              <span onClick={handleFilterReset}>Clear all</span>
            </div>
          )}
        </div>

        {/* <div className="border border-core-grey rounded p-1 gap-1 flex justify-end w-fit my-auto ml-2 bg-white sm:ml-0">
          <Tooltip text="Card View">
            <button
              className={`p-2 hover:bg-core-black group rounded transition ease-in ${
                activeButton === 'cardView' ? 'bg-core-black' : ''
              }`}
              onClick={() => handleButtonClick('cardView')}
            >
              {cardView}
            </button>
          </Tooltip> 
          <Tooltip text="Table View">
            <button
              className={`p-2 hover:bg-core-black hover:text-core-yellow group rounded transition ease-in ${
                activeButton === 'listView' ? 'bg-core-black' : ''
              }`}
              onClick={() => handleButtonClick('listView')}
            >
              {listView}
            </button>
          </Tooltip>
        </div> */}
      </div>

      {isAnyFilterApplied && (
        <div className="text-uppercase align-items-center font-bold w-full sm:hidden flex my-3">
          <span onClick={handleFilterReset}>Clear All</span>
        </div>
      )}

      <div className="gap-2 hidden sm:flex">
        <div className="my-3 flex gap-2 w-full flex-wrap">
          <Pills allPills={allPills} removePill={removePill} isBuyerLocation={props.buyerLocation} />
        </div>
      </div>
    </>
  );
};

export default HomeListingFiltersBar;
