import React, { FC, useEffect, useState } from 'react';
import { ChevronRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import Avatar from 'src/app/shared/components/Avatar/Avatar';
import SideBarMessages from './sideBarMessages';
import { useGetMessageThreads } from 'src/hooks/useMessages';
import Pagination from 'src/app/shared/components/Pagination/Pagination';
import { MessageThread } from 'src/types/messages';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import EmptyState from 'src/app/shared/components/emptyState/EmptyState';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import ConfirmationModal from '../components/confirmationModal';
import { archiveMessages } from 'src/api/messages-new';
import { useGetUser } from 'src/hooks';

interface AllDataProps {
  subscriptionName: string;
}

interface MessagePartner {
  uid: string;
  username: string;
}

const getMessagePartner = (thread: MessageThread, currentUserUid: string): MessagePartner => {
  if (thread.originatorUid == currentUserUid) {
    return { uid: thread.recipientUid, username: thread.recipientUsername };
  }
  return { uid: thread.originatorUid, username: thread.originatorUsername };
};

const AllDataTable: FC<AllDataProps> = ({ subscriptionName }) => {
  //  To implement later const [checked, setChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: messageThreadsResponse, isPending, refetch } = useGetMessageThreads();
  const [showMoreActions, setShowMoreActions] = useState<{ [key: number]: boolean }>({});
  const [, setButtonActive] = useState(false);
  const [selectedMessagesThread, setSelectedMessagesThread] = useState<string>('');
  const { alerts, createToast } = useAlert();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState<string>('');
  const { data: userResponse } = useGetUser();

  const sortData = (data: MessageThread[]) => {
    if (data) {
      const filteredData = data.filter((thread) => !thread.userArchived);
      const sortedData = [...filteredData].sort((a, b) => {
        const lastMessageA = a.messages[a.messages.length - 1];
        const lastMessageB = b.messages[b.messages.length - 1];
        const valueA = new Date(lastMessageA.createdAt).getTime();
        const valueB = new Date(lastMessageB.createdAt).getTime();
        return valueB - valueA;
      });
      return sortedData;
    }

    return [];
  };

  const sortedData = sortData(messageThreadsResponse?.messageThreads || []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (!e.target.closest('.show-more-actions-container')) {
        setShowMoreActions((prevState) => {
          const newState = { ...prevState };
          Object.keys(newState).forEach((key: any) => {
            const index = parseInt(key);
            if (!isNaN(index) && newState[key]) {
              newState[key] = !newState[key];
            }
          });
          return newState;
        });
        setButtonActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMoreActions]);

  // Implement later
  // const handleCheckboxChange = () => {
  //   setChecked(!checked);
  // };

  const handleContactBuyerSuccess = () => {
    refetch();
  };

  const handleOnConfirm = (uid: string) => {
    handleArchiveMessage(uid);
    refetch();
  };

  const handleShowMoreActions = (index: number) => {
    setShowMoreActions((prevState) => {
      const newState = { ...prevState };

      Object.keys(newState).forEach((key: any) => {
        const otherIndex = parseInt(key);
        if (!isNaN(otherIndex) && otherIndex !== index && newState[key]) {
          newState[key] = false;
        }
      });
      newState[index] = !newState[index];

      return newState;
    });
  };

  const handleOpenConfirmationModal = (uid: string) => {
    setIsModalConfirmationOpen(true);
    setMessageToDelete(uid);
  };

  const handleArchiveMessage = async (uid: string) => {
    try {
      await archiveMessages(uid);
      refetch();
      createToast({
        text: 'Message delete successfully',
        variant: 'success',
        // to implement later onRestore: () => handleRestoreMessage(uid),
      });
    } catch (error) {
      console.log('Error deleting message:', error);
      createToast({
        text: 'Error sending request',
        variant: 'warning',
      });
    }
  };

  // to implement later
  // const handleRestoreMessage = async (uid: string) => {
  //   try {
  //     await archiveMessages(uid);
  //     createToast({ text: 'Message restore successfully', variant: 'success' });
  //     refetch();
  //   } catch (error) {
  //     console.log('Error deleting message:', error);
  //     createToast({ text: 'Error sending request', variant: 'warning' });
  //   }
  // };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalConfirmationOpen(false);
  };

  const openSideBarMessages = (val: MessageThread) => {
    setSelectedMessagesThread(val.uid);
    setIsModalOpen(true);
  };

  const handleItemsPerPageChange = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(1);
  };

  const formatMessageDate = (createdAt: string) => {
    const currentDate = new Date().getTime();
    const messageDate = new Date(createdAt).getTime();
    const differenceInSeconds = Math.floor((currentDate - messageDate) / 1000);

    if (differenceInSeconds < 60) {
      return 'just now';
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes}m ago`;
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      return `${hours}h ago`;
    } else if (differenceInSeconds < 604800) {
      const days = Math.floor(differenceInSeconds / 86400);
      return `${days}d ago`;
    } else if (differenceInSeconds < 2419200) {
      const weeks = Math.floor(differenceInSeconds / 604800);
      return `${weeks}w ago`;
    } else if (differenceInSeconds < 31536000) {
      const months = Math.floor(differenceInSeconds / 2592000);
      return `${months}M ago`;
    } else if (differenceInSeconds > 31536000) {
      const years = Math.floor(differenceInSeconds / 31536000);
      return `${years}y ago`;
    }
  };

  if (isPending) {
    return (
      <div className="w-full flex justify-start border rounded-lg overflow-auto sm:overflow-visible md:overflow-auto lg:overflow-hidden xl:overflow-auto">
        <div className="w-full">
          <Skeleton type="table" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex justify-start border rounded-lg overflow-auto sm:overflow-visible md:overflow-auto lg:overflow-hidden xl:overflow-auto">
        <table className="w-full bg-white rounded-lg">
          <thead className="rounded-lg">
            <tr className="rounded-lg">
              {/* // Implement later
              <th className="p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap min-w-[70px]">
                <div className="flex gap-4 justify-start md:w-1/2 w-full my-auto mx-auto items-center">
                  <input
                    type="checkbox"
                    className="hidden"
                    id="checkbox-all"
                    checked={checked}
                    onClick={handleCheckboxChange}
                  />
                  <label
                    htmlFor="checkbox-all"
                    className={`h-4 w-4 my-auto group flex items-center justify-center cursor-pointer border-2 border-gray-700 rounded-[4px] ${
                      checked ? 'bg-core-black' : 'bg-white'
                    }`}
                  >
                    {checked && <CheckIcon className="w-2.5 h-2.5 text-white stroke-2" />}
                  </label>
                </div>
              </th> */}
              <th className="p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap">
                <span>FROM</span>
              </th>

              {/* // Implement later
              <th className="p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap">
                <span>LISTING ID</span>
              </th> */}

              <th className="p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap">
                <span>Preview</span>
              </th>

              <th className="p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap">
                <span>Date</span>
              </th>
              <th colSpan={2} className="border-bottom whitespace-nowrap"></th>
            </tr>
          </thead>
          {sortedData && sortedData.length > 0 ? (
            <tbody>
              {sortedData &&
                currentItems?.map((thread, index) => (
                  <tr
                    key={index}
                    className="hover:bg-yellow-50 transition ease-in rounded-lg cursor-pointer"
                    onClick={() => {
                      openSideBarMessages(thread);
                    }}
                  >
                    {/* // Implement later
                    <td className="p-3">
                      <div className="flex gap-4 justify-start md:w-1/2 w-full my-auto mx-auto items-center">
                        <input type="checkbox" className="hidden" checked={checked} onClick={handleCheckboxChange} />
                        <label
                          className={`h-4 w-4 my-auto group flex items-center justify-center cursor-pointer border-2 border-gray-400 rounded-[4px] ${
                            checked ? 'bg-core-black' : 'bg-white'
                          }`}
                        >
                          {checked && <CheckIcon className="w-2.5 h-2.5 text-white stroke-2" />}
                        </label>
                      </div>
                    </td> */}
                    <td>
                      <div className="p-3 flex gap-2 justify-start text-left items-center whitespace-nowrap">
                        <Avatar
                          size="md2"
                          userName={getMessagePartner(thread, userResponse?.userProfile.uid ?? '')
                            .username.charAt(0)
                            .toUpperCase()}
                        />
                        <span className="text-core-grey text-sm font-semibold max-w-[150px] truncate">
                          {thread.listingListingNum
                            ? 'Listing ' + thread.listingListingNum
                            : getMessagePartner(thread, userResponse?.userProfile.uid ?? '').username}
                        </span>
                      </div>
                    </td>
                    {/* // Implement later
                    <td className="p-3 flex gap-2 justify-start text-left items-center my-auto">
                    <span className="text-core-black text-sm font-semibold py-2">12345</span>
                  </td> */}
                    <td className="text-core-grey text-sm font-semibold max-w-[400px] text-left truncate px-3">
                      {thread.messages[thread.messages.length - 1].content}
                    </td>
                    <td className="text-core-grey text-sm font-semibold">
                      {formatMessageDate(thread.messages[thread.messages.length - 1].createdAt)}
                    </td>
                    <td className="p-2 my-auto items-center" onClick={(e) => e.stopPropagation()}>
                      <button
                        className={`hover:bg-white rounded ${
                          showMoreActions[index] ? 'border-1 border-blue-500 bg-white' : 'border-0'
                        } hover:border-1 transition ease-in mt-1`}
                        onClick={() => handleShowMoreActions(index)}
                      >
                        <EllipsisHorizontalIcon className="text-gray-400 h-8 w-8 hover:text-core-black cursor-pointer transition ease-in" />
                      </button>

                      {showMoreActions[index] && (
                        <div className="bg-white rounded border-1 border-blue-500 p-2 absolute -mt-10 right-[10%] mr-4">
                          <ul className="p-0 m-0 text-left">
                            <li
                              className="text-base font-medium rounded px-3 py-2 hover:bg-neutral-50 transition ease-in cursor-pointer"
                              onClick={() => {
                                openSideBarMessages(thread);
                                handleShowMoreActions(index);
                              }}
                            >
                              View
                            </li>
                            <li
                              className="text-base font-medium rounded px-3 py-2 hover:bg-neutral-50 transition ease-in cursor-pointer"
                              onClick={() => {
                                handleOpenConfirmationModal(thread.uid);
                              }}
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                    <td className="m-auto">
                      <button
                        onClick={() => {
                          openSideBarMessages(thread);
                        }}
                        className="p-1 bg-gray-50 hover:bg-core-yellow transition ease-in rounded-sm mr-2"
                      >
                        <ChevronRightIcon className="h-3 w-3" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : null}
        </table>
        {alerts}
      </div>

      {sortedData && sortedData.length === 0 && (
        <>
          {subscriptionName === 'Pro' && userResponse?.userProfile.hasActiveListing ? (
            <div className="w-full mx-auto">
              <EmptyState text="You have no notifications yet" help={false} />
            </div>
          ) : null}
        </>
      )}

      {sortedData && sortedData.length > 10 && (
        <div className="sm:mb-4 mb-20">
          {sortedData && sortedData.length > itemsPerPage && (
            <Pagination
              totalItems={sortedData.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
              onItemsPerPageChange={(itemsPerPage) => handleItemsPerPageChange(itemsPerPage)}
              nameListing="messages"
            />
          )}
        </div>
      )}

      <SideBarMessages
        isOpen={isModalOpen}
        onClose={closeModal}
        messagesThread={messageThreadsResponse?.messageThreads.find((thread) => thread.uid === selectedMessagesThread)}
        onContactBuyerSuccess={handleContactBuyerSuccess}
      />

      <ConfirmationModal
        messageToDelete={messageToDelete}
        isOpen={isModalConfirmationOpen}
        onClose={closeModal}
        onConfirm={handleOnConfirm}
      />
    </>
  );
};

export default AllDataTable;
