import React, { FC, useEffect, useState } from 'react';
import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Button } from 'src/app/shared/components/Buttons/Button';
import { useGetMarketplaceListingByIdFree, useGetSliceDetails } from 'src/hooks/useGetMarketplaceListings';
import { formatCurrency, formatLastSyncShort } from 'src/app/shared/utils/formatters';
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import PayWall from './PayWall';
import { useGetSubscription } from 'src/hooks/useSubscription';
import { ReactComponent as ExpandIcon } from '../../../../../styles/assets/icons/solid/expandSolid.svg';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import Tooltip from 'src/app/shared/components/Tooltip/Tooltip';
import UpgradeModal from './UpgradeModal';
import TextareaComponent from 'src/app/shared/components/Textarea/Textarea';

import useAlert from 'src/app/shared/components/Toast/useAlert';
import { createMessage } from 'src/api/messages-new';
import ModalPivotTable from 'src/app/shared/components/Graphs/PivotTables/ModalPivotTable';
import { ShareIcon } from '@heroicons/react/24/outline';
import { PostListingView } from 'src/api/policies';
import { useAppSelector } from 'src/app/hooks';
import { usePoliciesByMarketplaceListingId } from 'src/hooks/usePolicies';
import { userStateSelector } from 'src/app/user/UserStoreSelectors';

ChartJS.register(ArcElement, ChartTooltip, Legend);

interface FlyoutMatchesProps {
  onClose: () => void;
  sliceUid: string;
  porcentage: string | undefined;
  createdAt: string | undefined;
  isEnterprise: boolean | false;
  buyerUid: string;
  createdByUser: string | undefined;
}

const FlyoutMatches: FC<FlyoutMatchesProps> = ({
  onClose,
  sliceUid,
  porcentage,
  createdAt,
  isEnterprise,
  buyerUid,
  createdByUser,
}) => {
  const [topOpen, setTopOpen] = useState(true);

  const { data: subscriptionResponse } = useGetSubscription();
  const isPaywallVisible =
    subscriptionResponse?.userSubscription === null || subscriptionResponse?.userSubscription === 'free';

  const { data: sliceFreeResponse } = useGetMarketplaceListingByIdFree(sliceUid || '');

  const { data: sliceDetailsResponse, isPending } = isPaywallVisible
    ? useGetMarketplaceListingByIdFree(sliceUid || '')
    : useGetSliceDetails(sliceUid || '');

  const { data: listingPolicyData } = isPaywallVisible
    ? usePoliciesByMarketplaceListingId('')
    : usePoliciesByMarketplaceListingId(sliceUid || '');

  const formattedDate = createdAt ? formatLastSyncShort(createdAt) : '';

  const handleOpenTop = () => {
    setTopOpen(!topOpen);
  };

  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [showTextMessage, setShowTextMessage] = useState(false);
  const [showModalPivotTable, setShowPivotTable] = useState(false);
  const [showSharedMessageCopy, setShowSharedMessageCopy] = useState(false);

  const handleContactSeller = () => {
    isPaywallVisible ? setOpenUpgradeModal(!openUpgradeModal) : setShowTextMessage(!showTextMessage);
  };

  const handleOpenModalPivotTable = () => {
    setShowPivotTable(!showModalPivotTable);
  };

  const handleContactSellerPreventDefault = () => {
    setOpenUpgradeModal(false);
    setShowTextMessage(false);
  };

  const [messageContentInput, setMessageContent] = useState<string>('');

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageContent(e.target.value);
  };

  const getMatchScoreClasses = (score: string | '') => {
    const numericScore = parseFloat(score);

    if (numericScore >= 80) {
      return 'text-[#276EF1]';
    } else if (numericScore >= 60) {
      return 'text-[#7DA8F7]';
    } else if (numericScore >= 50) {
      return 'text-[#BED4FB]';
    } else {
      return 'text-[#E8E8E8]';
    }
  };

  const getStateFullName = (stateCode: string): string => {
    if (UsStatesOptions[stateCode as keyof typeof UsStatesOptions]) {
      return UsStatesOptions[stateCode as keyof typeof UsStatesOptions];
    }
    return stateCode;
  };

  const getPercentageWidth = (percentage: number) => {
    return `${Math.round(percentage)}%`;
  };

  const carrierSummariesFree = sliceFreeResponse?.listing?.carrierSummaries || [];
  const lobSummariesFree = sliceFreeResponse?.listing?.lobSummaries || [];
  const stateSummariesFree = sliceFreeResponse?.listing?.stateSummaries || [];
  const insuranceTypeFree = sliceFreeResponse?.listing?.insuranceType || [];
  const policyStatusFree = sliceFreeResponse?.listing?.policyStatus || [];

  const carrierSummaries = sliceDetailsResponse?.listing?.carrierSummaries || [];
  const lobSummaries = sliceDetailsResponse?.listing?.lobSummaries || [];
  const lobSummariesCustomers = sliceDetailsResponse?.listing?.lobSummariesCustomer || [];
  const stateSummaries = sliceDetailsResponse?.listing?.stateSummaries || [];
  const carrierSummariesCustomer = sliceDetailsResponse?.listing?.carrierSummariesCustomer || [];
  const lobSummariesCustomer = sliceDetailsResponse?.listing?.lobSummariesCustomer || [];
  const stateSummariesCustomer = sliceDetailsResponse?.listing?.stateSummariesCustomer || [];
  const policyStatus = sliceDetailsResponse?.listing?.policyStatus || [];
  const paymentPlan = sliceDetailsResponse?.listing?.paymentPlan || [];
  const claims = sliceDetailsResponse?.listing?.claims || [];
  const topCustomers = sliceDetailsResponse?.listing?.topCustomers || [];

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeCustomerTabIndex, setActiveCustomerTabIndex] = useState(0);
  const [activeTabByBreackDownIndex, setActiveBreackDownTabIndex] = useState(0);

  const formatToUSD = (value: string) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Number(value));
  };

  const getPremium = (value: string) => `Premium: ${formatToUSD(value)}`;

  const doughnutOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const value = tooltipItem.raw;
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `${value}%`;
          },
          afterLabel: function () {
            return '';
          },
        },
      },
    },
  };

  const doughnutDataLoB = {
    labels: lobSummariesFree.map((item: any) => item.lob),
    datasets: [
      {
        data: lobSummariesFree.map((item: any) => item.percentage),
        backgroundColor: ['#F76908', '#E6E6E6'],
        borderColor: '#ffffff',
        borderWidth: 1,
        cutout: '75%',
      },
    ],
  };

  const doughnutDataCarrier = {
    labels: carrierSummariesFree.map((item: any) => item.lob),
    datasets: [
      {
        data: carrierSummariesFree.map((item: any) => item.percentage),
        backgroundColor: ['#F76908', '#E6E6E6'],
        borderColor: '#ffffff',
        borderWidth: 1,
        cutout: '75%',
      },
    ],
  };

  const doughnutDataState = {
    labels: stateSummariesFree.map((item: any) => item.state),
    datasets: [
      {
        data: stateSummariesFree.map((item: any) => item.percentage),
        backgroundColor: ['#F76908', '#E6E6E6'],
        borderColor: '#ffffff',
        borderWidth: 1,
        cutout: '75%',
      },
    ],
  };

  const colors = ['#00143A', '#0046C8', '#4A8AFF', '#A44300', '#FA6E0E', '#BBBBBB'];

  const uniqueLobSummaries = (summaries: any) => {
    const seen = new Set();
    return summaries.filter((item: any) => {
      const duplicate = seen.has(item.lob);
      seen.add(item.lob);
      return !duplicate;
    });
  };

  const filteredSummaries = uniqueLobSummaries(lobSummaries);
  const filteredSummariesCustomer = uniqueLobSummaries(lobSummariesCustomers);

  const doughnutDataTop5PremiumCarrier = {
    labels: carrierSummaries.map((item: any) => item.carrier),
    datasets: [
      {
        data: carrierSummaries.map((item: any) => item.dollars),
        backgroundColor: colors,
      },
    ],
  };

  const doughnutDataTop5PremiumLine = {
    labels: lobSummaries.map((item: any) => item.lob),
    datasets: [
      {
        data: lobSummaries.map((item: any) => item.dollars),
        backgroundColor: colors,
      },
    ],
  };

  const doughnutDataTop5PremiumState = {
    labels: stateSummaries.map((item: any) => item.state),
    datasets: [
      {
        data: stateSummaries.map((item: any) => item.dollars),
        backgroundColor: colors,
      },
    ],
  };

  const doughnutDataTop5CustomerCarrier = {
    labels: carrierSummariesCustomer.map((item: any) => item.carrier),
    datasets: [
      {
        data: carrierSummaries.map((item: any) => item.customer_count),
        backgroundColor: colors,
      },
    ],
  };

  const doughnutDataTop5CustomerLine = {
    labels: lobSummariesCustomer.map((item: any) => item.lob),
    datasets: [
      {
        data: lobSummaries.map((item: any) => item.customer_count),
        backgroundColor: colors,
      },
    ],
  };

  const doughnutCurrencyOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const value = tooltipItem.raw;
            return ` ${formatCurrency(value)}`;
          },
          afterLabel: function () {
            return '';
          },
        },
      },
    },
  };

  const doughnutDataTop5CustomerState = {
    labels: stateSummariesCustomer.map((item: any) => item.state),
    datasets: [
      {
        data: stateSummaries.map((item: any) => item.customer_count),
        backgroundColor: colors,
      },
    ],
  };

  const doughnutDataPolicyStatus = {
    labels: policyStatus.map((item: any) => item.policy_status),
    datasets: [
      {
        data: policyStatus.map((item: any) => item.policy_percentage),
        backgroundColor: colors,
      },
    ],
  };

  const doughnutDataPaymentPlan = {
    labels: paymentPlan.map((item: any) => item.payment_plan),
    datasets: [
      {
        data: paymentPlan.map((item: any) => item.policy_percentage),
        backgroundColor: colors,
      },
    ],
  };

  const doughnutDataClaims = {
    labels: claims.map((item: any) => item.number_claims),
    datasets: [
      {
        data: paymentPlan.map((item: any) => item.policy_percentage),
        backgroundColor: colors,
      },
    ],
  };

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  const handleTabCustomerClick = (index: number) => {
    setActiveCustomerTabIndex(index);
  };

  const handleTabByBreackdowClick = (index: number) => {
    setActiveBreackDownTabIndex(index);
  };

  const tabsByBreakDown = [
    {
      label: 'Policy status',
      content: (
        <div>
          <div className="w-full block md:flex my-5 gap-4">
            <div className="w-[255px] mx-auto md:mx-0">
              <Doughnut
                data={doughnutDataPolicyStatus}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>

            <div className="w-full mt-3 md:my-auto block overflow-x-auto">
              <table className="w-full">
                <thead className="text-core-grey font-normal text-xs text-left">
                  <tr className="space-x-2">
                    <th className="px-2">Name</th>
                    <th className="px-2">Policies</th>
                    <th className="px-2">%</th>
                    <th className="px-2">Premium</th>
                    <th className="px-2">%</th>
                    <th className="px-2">Customers</th>
                    <th className="px-2">%</th>
                  </tr>
                </thead>

                <tbody>
                  {policyStatus.map((item: any, index: number) => (
                    <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0 text-sm">
                      <td className="py-3 px-2">
                        <div className="flex gap-2">
                          <div
                            className="w-3 h-3 rounded-full my-auto"
                            style={{ backgroundColor: colors[index] }}
                          ></div>
                          <div>{item.policy_status}</div>
                        </div>
                      </td>
                      <td className="py-3 px-2">{item.policy_count}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.percentage)}</td>
                      <td className="py-3 px-2">{formatCurrency(item.dollars)}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.policy_percentage)}</td>
                      <td className="py-3 px-2">{item.customer_count}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.customer_percentage)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ),
    },

    {
      label: 'Payment Plan',
      content: (
        <div>
          <div className="w-full block md:flex my-5 gap-4">
            <div className="w-[255px] mx-auto md:mx-0">
              <Doughnut
                data={doughnutDataPaymentPlan}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>

            <div className="w-full mt-3 md:my-auto block overflow-x-auto">
              <table className="w-full">
                <thead className="text-core-grey font-normal text-xs text-left">
                  <tr className="space-x-2">
                    <th className="px-2">Name</th>
                    <th className="px-2">Policies</th>
                    <th className="px-2">%</th>
                    <th className="px-2">Premium</th>
                    <th className="px-2">%</th>
                    <th className="px-2">Customers</th>
                    <th className="px-2">%</th>
                  </tr>
                </thead>

                <tbody>
                  {paymentPlan.map((item: any, index: number) => (
                    <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0 text-sm">
                      <td className="py-3 px-2">
                        <div className="flex gap-2">
                          <div
                            className="w-3 h-3 rounded-full my-auto"
                            style={{ backgroundColor: colors[index] }}
                          ></div>
                          <div>{item.payment_plan}</div>
                        </div>
                      </td>
                      <td className="py-3 px-2">{item.policy_count}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.percentage)}</td>
                      <td className="py-3 px-2">{formatCurrency(item.dollars)}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.policy_percentage)}</td>
                      <td className="py-3 px-2">{item.customer_count}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.customer_percentage)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ),
    },

    {
      label: 'Claims (#)',
      content: (
        <div>
          <div className="w-full block md:flex my-5 gap-4">
            <div className="w-[255px] mx-auto md:mx-0">
              <Doughnut
                data={doughnutDataClaims}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>

            <div className="w-full mt-3 md:my-auto block overflow-x-auto">
              <table className="w-full">
                <thead className="text-core-grey font-normal text-xs text-left">
                  <tr className="space-x-2">
                    <th className="px-2">Name</th>
                    <th className="px-2">Policies</th>
                    <th className="px-2">%</th>
                    <th className="px-2">Premium</th>
                    <th className="px-2">%</th>
                    <th className="px-2">Customers</th>
                    <th className="px-2">%</th>
                  </tr>
                </thead>

                <tbody>
                  {claims.map((item: any, index: number) => (
                    <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0 text-sm">
                      <td className="py-3 px-2">
                        <div className="flex gap-2">
                          <div
                            className="w-3 h-3 rounded-full my-auto"
                            style={{ backgroundColor: colors[index] }}
                          ></div>
                          <div>{item.number_claims}</div>
                        </div>
                      </td>
                      <td className="py-3 px-2">{item.policy_count}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.percentage)}</td>
                      <td className="py-3 px-2">{formatCurrency(item.dollars)}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.policy_percentage)}</td>
                      <td className="py-3 px-2">{item.customer_count}</td>
                      <td className="py-3 px-2">{getPercentageWidth(item.customer_percentage)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const tabsByPremium = [
    {
      label: 'CARRIER',
      content: (
        <div>
          <div className="w-full flex my-5">
            <div className="w-[240px] mx-auto">
              <Doughnut data={doughnutDataTop5PremiumCarrier} options={doughnutCurrencyOptions} />
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead className="text-core-grey font-medium text-xs text-left">
                <tr className="space-x-2">
                  <th className="px-2">Name</th>
                  <th className="px-2">Premium</th>
                  <th className="px-2">%</th>
                  <th className="px-2">Policies</th>
                </tr>
              </thead>

              <tbody>
                {carrierSummaries.map((item: any, index: number) => (
                  <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0">
                    <td className="py-3 px-2">
                      <div className="flex gap-2">
                        <div className="w-3 h-3 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                        <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                          {item.carrier === 'Remaining' ? 'Rest of Slice' : item.carrier}
                        </div>
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {formatCurrency(item.dollars)}
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {getPercentageWidth(item.percentage)}
                      </div>
                    </td>
                    <td className="py-3 px-2">{item.policy_count}</td>
                  </tr>
                ))}
                <tr className="text-gray-900 font-bold text-sm">
                  <td className="py-3 px-2 uppercase">Totals</td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {formatCurrency(carrierSummaries.reduce((acc, curr) => acc + parseFloat(curr.dollars), 0))}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {`${carrierSummaries.reduce((acc, curr) => acc + curr.percentage, 0).toFixed(0)}%`}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    {carrierSummaries.reduce((acc, curr) => acc + curr.policy_count, 0).toString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
    {
      label: 'LINE',
      content: (
        <div>
          <div className="w-full flex my-5">
            <div className="w-[240px] mx-auto">
              <Doughnut data={doughnutDataTop5PremiumLine} options={doughnutCurrencyOptions} />
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead className="text-core-grey font-medium text-xs text-left">
                <tr className="space-x-2">
                  <th className="px-2">Name</th>
                  <th className="px-2">Premium</th>
                  <th className="px-2">%</th>
                  <th className="px-2">Policies</th>
                </tr>
              </thead>

              <tbody>
                {filteredSummaries.map((item: any, index: number) => (
                  <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0">
                    <td className="py-3 px-2">
                      <div className="flex gap-2">
                        <div className="w-3 h-3 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                        <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                          {item.lob === 'Remaining' ? 'Rest of Slice' : item.lob}
                        </div>
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {formatCurrency(item.dollars)}
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {getPercentageWidth(item.percentage)}
                      </div>
                    </td>
                    <td className="py-3 px-2">{item.policy_count}</td>
                  </tr>
                ))}
                <tr className="text-gray-900 font-bold text-sm">
                  <td className="py-3 px-2 uppercase">Totals</td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {formatCurrency(lobSummaries.reduce((acc, curr) => acc + parseFloat(curr.dollars), 0))}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {`${lobSummaries.reduce((acc, curr) => acc + curr.percentage, 0).toFixed(0)}%`}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    {lobSummaries.reduce((acc, curr) => acc + curr.policy_count, 0).toString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
    {
      label: 'STATE',
      content: (
        <div>
          <div className="w-full flex my-5">
            <div className="w-[240px] mx-auto">
              <Doughnut data={doughnutDataTop5PremiumState} options={doughnutCurrencyOptions} />
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead className="text-core-grey font-medium text-xs text-left">
                <tr className="space-x-2">
                  <th className="px-2">Name</th>
                  <th className="px-2">Premium</th>
                  <th className="px-2">%</th>
                  <th className="px-2">Policies</th>
                </tr>
              </thead>

              <tbody>
                {stateSummaries.map((item: any, index: number) => (
                  <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0">
                    <td className="py-3 px-2">
                      <div className="flex gap-2">
                        <div className="w-3 h-3 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                        <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                          {item.state === 'Remaining' ? 'Rest of Slice' : getStateFullName(item.state)}
                        </div>
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {formatCurrency(item.dollars)}
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {getPercentageWidth(item.percentage)}
                      </div>
                    </td>
                    <td className="py-3 px-2">{item.policy_count}</td>
                  </tr>
                ))}
                <tr className="text-gray-900 font-bold text-sm">
                  <td className="py-3 px-2 uppercase">Totals</td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {formatCurrency(stateSummaries.reduce((acc, curr) => acc + parseFloat(curr.dollars), 0))}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {`${stateSummaries.reduce((acc, curr) => acc + curr.percentage, 0).toFixed(0)}%`}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    {stateSummaries.reduce((acc, curr) => acc + curr.policy_count, 0).toString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
  ];

  const tabsByCustomer = [
    {
      label: 'CARRIER',
      content: (
        <div>
          <div className="w-full flex my-5">
            <div className="w-[240px] mx-auto">
              <Doughnut
                data={doughnutDataTop5CustomerCarrier}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead className="text-core-grey font-medium text-xs text-left">
                <tr className="space-x-2">
                  <th className="px-2">Name</th>
                  <th className="px-2">Customers</th>
                  <th className="px-2">%</th>
                  <th className="px-2">Policies</th>
                </tr>
              </thead>

              <tbody>
                {carrierSummariesCustomer.map((item: any, index: number) => (
                  <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0">
                    <td className="py-3 px-2">
                      <div className="flex gap-2">
                        <div className="w-3 h-3 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                        <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                          {item.carrier === 'Remaining' ? 'Rest of Slice' : item.carrier}
                        </div>
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {item.customer_count}
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {getPercentageWidth(item.customer_percentage)}
                      </div>
                    </td>
                    <td className="py-3 px-2">{item.policy_count}</td>
                  </tr>
                ))}
                <tr className="text-gray-900 font-bold text-sm">
                  <td className="py-3 px-2 uppercase">Totals</td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {carrierSummariesCustomer.reduce((acc, curr) => acc + curr.customer_count, 0)}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {`${carrierSummariesCustomer.reduce((acc, curr) => acc + curr.percentage, 0).toFixed(0)}%`}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    {carrierSummariesCustomer.reduce((acc, curr) => acc + curr.policy_count, 0).toString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
    {
      label: 'LINE',
      content: (
        <div>
          <div className="w-full flex my-5">
            <div className="w-[240px] mx-auto">
              <Doughnut
                data={doughnutDataTop5CustomerLine}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead className="text-core-grey font-medium text-xs text-left">
                <tr className="space-x-2">
                  <th className="py-3 px-2">Name</th>
                  <th className="py-3 px-2">Premium</th>
                  <th className="py-3 px-2">%</th>
                  <th className="py-3 px-2">Policies</th>
                </tr>
              </thead>

              <tbody>
                {filteredSummariesCustomer.map((item: any, index: number) => (
                  <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0">
                    <td className="py-3 px-2">
                      <div className="flex gap-2">
                        <div className="w-3 h-3 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                        <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                          {item.lob === 'Remaining' ? 'Rest of Slice' : item.lob}
                        </div>
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {formatCurrency(item.dollars)}
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {getPercentageWidth(item.percentage)}
                      </div>
                    </td>
                    <td className="py-3 px-2">{item.policy_count}</td>
                  </tr>
                ))}
                <tr className="text-gray-900 font-bold text-sm">
                  <td className="py-3 px-2 uppercase">Totals</td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {formatCurrency(lobSummariesCustomers.reduce((acc, curr) => acc + parseFloat(curr.dollars), 0))}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {`${lobSummariesCustomers.reduce((acc, curr) => acc + curr.percentage, 0).toFixed(0)}%`}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    {lobSummariesCustomers.reduce((acc, curr) => acc + curr.policy_count, 0).toString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
    {
      label: 'STATE',
      content: (
        <div>
          <div className="w-full flex my-5">
            <div className="w-[240px] mx-auto">
              <Doughnut
                data={doughnutDataTop5CustomerState}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead className="text-core-grey font-medium text-xs text-left">
                <tr className="space-x-2">
                  <th className="px-2">Name</th>
                  <th className="px-2">Premium</th>
                  <th className="px-2">%</th>
                  <th className="px-2">Policies</th>
                </tr>
              </thead>

              <tbody>
                {stateSummariesCustomer.map((item: any, index: number) => (
                  <tr key={index} className="space-x-2 border-b-[1px] border-gray-100 last:border-b-0">
                    <td className="py-3 px-2">
                      <div className="flex gap-2">
                        <div className="w-3 h-3 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                        <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                          {item.state === 'Remaining' ? 'Rest of Slice' : getStateFullName(item.state)}
                        </div>
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {formatCurrency(item.dollars)}
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                        {getPercentageWidth(item.percentage)}
                      </div>
                    </td>
                    <td className="py-3 px-2">{item.policy_count}</td>
                  </tr>
                ))}
                <tr className="text-gray-900 font-bold text-sm">
                  <td className="py-3 px-2 uppercase">Totals</td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {formatCurrency(stateSummariesCustomer.reduce((acc, curr) => acc + parseFloat(curr.dollars), 0))}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    <div className="max-w-[150px] font-medium text-sm truncate text-gray-900">
                      {`${stateSummariesCustomer.reduce((acc, curr) => acc + curr.percentage, 0).toFixed(0)}%`}
                    </div>
                  </td>
                  <td className="py-3 px-2">
                    {stateSummariesCustomer.reduce((acc, curr) => acc + curr.policy_count, 0).toString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
  ];

  const { alerts, createToast } = useAlert();

  const isMessageValid = messageContentInput.length >= 3;

  const handleSendContactRequest = async () => {
    try {
      const messageContent = messageContentInput;
      const messageThreadUid = buyerUid;
      const messageListingUid = sliceUid;

      await createMessage(messageContent, messageThreadUid, null, messageListingUid);
      createToast({ text: 'Request sent! Check the inbox', variant: 'success' });
      setMessageContent('');
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleCopyClipboard = (uid: string) => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/slices?uid=${uid}`;
    navigator.clipboard.writeText(fullUrl);
    setShowSharedMessageCopy(true);
    setTimeout(() => {
      setShowSharedMessageCopy(false);
    }, 3000);
  };

  const { userProfile } = useAppSelector(userStateSelector);
  const sessionEmail = userProfile.username;

  useEffect(() => {
    const checkAndPostListingView = async () => {
      const lastExecution = localStorage.getItem(`lastExecution_${sliceUid}`);
      const now = new Date().getTime();

      if (lastExecution && now - parseInt(lastExecution) < 24 * 60 * 60 * 1000) {
        return;
      }

      if (createdByUser !== sessionEmail) {
        try {
          await PostListingView(sliceUid);
          localStorage.setItem(`lastExecution_${sliceUid}`, now.toString());
        } catch (error) {
          console.error('Failed to post listing view:', error);
        }
      }
    };

    checkAndPostListingView();
  }, [sliceUid, createdByUser, sessionEmail]);

  return (
    <>
      {!showModalPivotTable ? (
        <div
          onClick={(event) => {
            event.preventDefault();
            handleContactSellerPreventDefault();
            onClose();
          }}
          className="fixed top-0 p-3 sm:px-0 left-0 w-full h-full backdrop-blur-md bg-black/50 flex justify-center justify-items-center z-[100]"
        >
          {openUpgradeModal ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <UpgradeModal onBack={handleContactSeller} />
            </div>
          ) : (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="bg-white rounded md:h-[96vh] w-full md:w-[80%] h-[99vh] fixed md:right-3"
            >
              <div className="w-full flex p-6 gap-3">
                <div className="w-4/5 flex flex-col justify-start">
                  {isPending ? (
                    <>
                      <div className="bg-neutral-100 w-[150px] p-2.5 animate-pulse"></div>
                      <div className="bg-neutral-100 w-[300px] p-2 mt-2 animate-pulse"></div>
                    </>
                  ) : (
                    <>
                      <h1 className="text-core-black text-2xl font-medium text-left m-0 p-0">
                        Listing {sliceDetailsResponse?.listing?.listingNum}
                      </h1>
                      <div className="w-full gap-2 flex text-left mt-1 text-sm font-bold uppercase">
                        {isEnterprise && (
                          <>
                            <p className={`m-0 p-0 ${getMatchScoreClasses(porcentage || '')}`}>{porcentage}% MATCH</p>
                            <span className="bg-gray-600 h-1 w-1 my-auto rounded-full"></span>
                          </>
                        )}
                        <p className="m-0 p-0 text-gray-600">Listed {formattedDate}</p>
                      </div>
                    </>
                  )}
                </div>
                <div className="w-1/5 flex justify-end gap-3">
                  <button
                    className="px-3 py-2.5 my-auto group transition ease-in hover:!bg-core-black hover:boder-core-black hover:!text-white border-[1px] items-center h-fit rounded-full flex gap-2 bg-white border-[#DDDDDD] text-core-black text-xs font-medium"
                    onClick={() => handleCopyClipboard(sliceUid)}
                  >
                    <ShareIcon className="w-4 h-4 my-auto text-core-grey group-hover:!text-white" />
                    Share
                  </button>

                  {showSharedMessageCopy && (
                    <div className="w-fit px-3 py-2 bg-core-black rounded-lg absolute top-7 z-50 flex gap-2">
                      <CheckCircleIcon className="h-4 w-4 text-green-500 my-auto" />
                      <p className="text-white p-0 m-0 text-sm">Copied to Clipboard</p>
                    </div>
                  )}

                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      handleContactSellerPreventDefault();
                      onClose();
                    }}
                    className="my-auto p-2 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
                  >
                    <XMarkIcon className={`my-auto w-4 h-4 text-core-black group-hover:text-white`} />
                  </button>
                </div>
              </div>

              <div className="w-full overflow-y-auto md:max-h-[70vh] max-h-[80vh]">
                {isPending ? (
                  <div className="grid grid-cols-4 gap-3 px-6 pb-6">
                    {[...Array(4)].map((_, indexCard) => (
                      <div
                        className="border-[1px] border-gray-300 rounded-lg p-10 bg-neutral-100 animate-pulse"
                        key={indexCard}
                      ></div>
                    ))}
                  </div>
                ) : (
                  <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-3 px-6 pb-6">
                    <div className="bg-white border-[1px] border-gray-300 rounded-lg p-4 flex justify-start text-left flex-col text-core-black">
                      <h5 className="text-xl md:text-3xl font-medium">
                        {formatCurrency(sliceDetailsResponse?.listing.totalPremium || 0)}
                      </h5>
                      <p className="p-0 m-0 text-xs md:text-sm font-medium">Total Premium</p>
                    </div>
                    <div className="bg-white border-[1px] border-gray-300 rounded-lg p-4 flex justify-start text-left flex-col text-core-black">
                      <h5 className="text-xl md:text-3xl font-medium">
                        {formatCurrency(sliceDetailsResponse?.listing.premiumPerCustomer || 0)}
                      </h5>
                      <p className="p-0 m-0 text-xs md:text-sm font-medium">Avg. Premium / Customer</p>
                    </div>
                    <div className="bg-white border-[1px] border-gray-300 rounded-lg p-4 flex justify-start text-left flex-col text-core-black">
                      <h5 className="text-xl md:text-3xl font-medium">{sliceDetailsResponse?.listing.totalPolicies}</h5>
                      <p className="p-0 m-0 text-xs md:text-sm font-medium">Total Policies</p>
                    </div>
                    <div className="bg-white border-[1px] border-gray-300 rounded-lg p-4 flex justify-start text-left flex-col text-core-black">
                      <h5 className="text-xl md:text-3xl font-medium">
                        {sliceDetailsResponse?.listing.totalCustomers}
                      </h5>
                      <p className="p-0 m-0 text-xs md:text-sm font-medium">Unique Customers</p>
                    </div>
                  </div>
                )}

                {isPending ? (
                  <Skeleton type="graphFlyout" />
                ) : (
                  <div className="w-full px-6 pb-6">
                    <div className="bg-core-lightGrey rounded-lg p-4">
                      <div className="w-full flex">
                        <div className="w-1/2 flex justify-start">
                          <h2 className="text-core-black text-2xl font-medium">Summary</h2>
                        </div>
                        <div className="w-1/2 flex justify-end my-auto">
                          <button className="bg-white rounded-md py-2 px-2 hover:shadow" onClick={handleOpenTop}>
                            {topOpen ? (
                              <ChevronUpIcon className="w-4 h-4 my-auto" />
                            ) : (
                              <ChevronDownIcon className="w-4 h-4 my-auto" />
                            )}
                          </button>
                        </div>
                      </div>

                      {topOpen && (
                        <div className="w-full flex flex-col mt-4">
                          <div className="w-full flex justify-start">
                            <h3 className="text-core-black text-base font-medium mb-3">
                              Key Attributes <span className="text-gray-500">(% of Premium)</span>
                            </h3>
                          </div>

                          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2">
                            <div className="bg-white rounded-lg p-3">
                              <h3 className="text-core-grey text-sm font-bold uppercase md:text-center text-left">
                                LINE
                              </h3>
                              <div className="flex justify-end w-full -mt-5 md:mt-0 md:w-[90px] h-[90px] md:mx-auto md:block">
                                <Doughnut data={doughnutDataLoB} options={doughnutOptions} />
                              </div>
                              <h4 className="text-core-black text-base -mt-20 md:mt-2 font-medium text-left md:text-center">
                                {lobSummaries[0].lob || ''}
                              </h4>

                              <div className="relative text-center flex justify-start md:justify-center pb-4">
                                <Tooltip body={getPremium(lobSummaries[0]?.dollars)} position="top">
                                  <h5 className="text-core-grey text-left md:text-center text-sm font-medium">
                                    {lobSummaries[0].percentage || 0}%
                                  </h5>
                                </Tooltip>
                              </div>
                            </div>

                            <div className="bg-white rounded-lg p-3">
                              <h3 className="text-core-grey text-sm font-bold uppercase md:text-center text-left">
                                Carrier
                              </h3>
                              <div className="flex justify-end w-full -mt-5 md:mt-0 md:w-[90px] h-[90px] md:mx-auto md:block">
                                <Doughnut data={doughnutDataCarrier} options={doughnutOptions} />
                              </div>
                              <h4 className="text-core-black text-base -mt-20 md:mt-2 font-medium text-left md:text-center">
                                {carrierSummaries[0]?.carrier}
                              </h4>
                              <div className="relative text-center flex justify-start md:justify-center pb-4">
                                <Tooltip body={getPremium(carrierSummaries[0]?.dollars)} position="top">
                                  <h5 className="text-core-grey text-center text-sm font-medium">
                                    {carrierSummaries[0]?.percentage}%
                                  </h5>
                                </Tooltip>
                              </div>
                            </div>

                            <div className="bg-white rounded-lg p-3">
                              <h3 className="text-core-grey text-sm font-bold uppercase md:text-center text-left">
                                State
                              </h3>
                              <div className="flex justify-end w-full -mt-5 md:mt-0 md:w-[90px] h-[90px] md:mx-auto md:block">
                                <Doughnut data={doughnutDataState} options={doughnutOptions} />
                              </div>
                              <h4 className="text-core-black text-base -mt-20 md:mt-2 font-medium text-left md:text-center">
                                {getStateFullName(stateSummaries[0]?.state)}
                              </h4>
                              <div className="relative text-center flex justify-start md:justify-center pb-4">
                                <Tooltip body={getPremium(stateSummaries[0]?.dollars)} position="top">
                                  <h5 className="text-core-grey text-center text-sm font-medium">
                                    {stateSummaries[0]?.percentage}%
                                  </h5>
                                </Tooltip>
                              </div>
                            </div>
                          </div>

                          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
                            <div className="bg-white rounded-lg p-3">
                              <h3 className="text-core-grey text-sm font-bold uppercase text-left">POLICY TYPE</h3>
                              <div className="w-full flex gap-1 mb-2">
                                <div
                                  style={{ width: getPercentageWidth(insuranceTypeFree[0]?.percentage) }}
                                  className={`bg-gradient-to-r from-[#F76908] to-[#FFB74A] py-2 rounded-lg`}
                                ></div>
                                <div
                                  style={{ width: getPercentageWidth(insuranceTypeFree[1]?.percentage) }}
                                  className={`bg-gradient-to-r from-[#0B40A3] to-[#276EF1] py-2 rounded-lg`}
                                ></div>
                              </div>
                              <div className="w-full flex">
                                <div className="w-1/2 flex justify-start">
                                  <div className="relative text-center flex justify-start">
                                    <Tooltip body={getPremium(insuranceTypeFree[0]?.dollars)} position="top">
                                      <p className="m-0 p-0 text-core-black text-sm">
                                        {insuranceTypeFree[0]?.type_of_insurance}
                                        <span className="text-gray-500">({insuranceTypeFree[0]?.percentage}%)</span>
                                      </p>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="w-1/2 flex justify-center pr-3">
                                  <div className="relative">
                                    <Tooltip body={getPremium(insuranceTypeFree[1]?.dollars)} position="top">
                                      <p className="m-0 p-0 text-core-black text-sm">
                                        {insuranceTypeFree[1]?.type_of_insurance}
                                        <span className="text-gray-500">({insuranceTypeFree[1]?.percentage}%)</span>
                                      </p>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bg-white rounded-lg p-3">
                              <h3 className="text-core-grey text-sm font-bold uppercase text-left">POLICY STATUS</h3>
                              <div className="w-full flex gap-1 mb-2">
                                <div
                                  style={{ width: getPercentageWidth(policyStatusFree[0]?.percentage) }}
                                  className={`bg-gradient-to-r from-[#F76908] to-[#FFB74A] py-2 rounded-lg`}
                                ></div>
                                <div
                                  style={{ width: getPercentageWidth(policyStatusFree[1]?.percentage) }}
                                  className={`bg-gradient-to-r from-[#0B40A3] to-[#276EF1] py-2 rounded-lg`}
                                ></div>
                              </div>
                              <div className="w-full flex pb-3">
                                <div className="w-1/2 flex justify-start">
                                  <div className="relative">
                                    <Tooltip body={getPremium(policyStatusFree[0]?.dollars)} position="top">
                                      <p className="m-0 p-0 text-core-black text-sm">
                                        {policyStatusFree[0]?.policy_status}
                                        <span className="text-gray-500">({policyStatusFree[0]?.percentage}%)</span>
                                      </p>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="w-1/2 flex justify-center">
                                  <div className="relative">
                                    <Tooltip body={getPremium(policyStatusFree[1]?.dollars)} position="top">
                                      <p className="m-0 p-0 text-core-black text-sm">
                                        {policyStatusFree[1]?.policy_status}
                                        <span className="text-gray-500">({policyStatusFree[1]?.percentage}%)</span>
                                      </p>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="w-full mt-6 mb-3 flex justify-start">
                            <h3 className="text-lg font-medium">Other Attributes</h3>
                          </div>

                          <div className="w-full flex flex-wrap">
                            <div className="w-full md:w-1/2 flex justify-start flex-col">
                              <div className="w-full text-left">
                                <h4 className="uppercase text-sm font-bold text-gray-500 mb-3">Network</h4>

                                <p className="p-0 m-0 text-core-black text-base font-medium">
                                  {sliceDetailsResponse?.listing.network ? sliceDetailsResponse?.listing.network : '-'}
                                </p>
                              </div>
                              <div className="w-full text-left">
                                <h4 className="uppercase text-sm font-bold text-gray-500 mb-3">
                                  POLICY / CUSTOMER RATIO
                                </h4>

                                <p className="p-0 m-0 text-core-black text-base font-medium">
                                  {sliceDetailsResponse?.listing.policyToCustomer}
                                </p>
                              </div>
                            </div>
                            <div className="w-full md:w-1/2">
                              <div className="w-full text-left">
                                <h4 className="uppercase text-sm font-bold text-gray-500 mb-3">LISTING DESCRIPTION</h4>

                                <p className="p-0 m-0 text-core-black text-base font-medium">
                                  {sliceDetailsResponse?.listing.description
                                    ? sliceDetailsResponse?.listing.description
                                    : '-'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!isPending && (
                  <div className="w-full p-6">
                    <div className="w-full bg-core-lightGrey rounded-lg p-4 text-left">
                      <div className="w-full flex">
                        <div className="w-4/5 md:w-1/2">
                          <h2 className="text-core-black text-2xl font-medium mb-4">Listing Analysis</h2>
                        </div>

                        {!isPaywallVisible && (
                          <div className="w-1/5 md:w-1/2 flex justify-end">
                            <button
                              className="w-fit h-fit py-2 px-3 border rounded-full bg-white text-core-black font-medium text-base flex gap-2"
                              type="button"
                              onClick={() => handleOpenModalPivotTable()}
                            >
                              <ExpandIcon className="h-4 w-4 my-auto" />
                              Advanced
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="w-full">
                        {isPaywallVisible ? (
                          <PayWall />
                        ) : (
                          <div className="w-full flex gap-3 flex-wrap">
                            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
                              <div className="bg-white rounded-lg p-4 flex flex-wrap h-fit">
                                <div className="w-3/5 flex justify-start flex-col">
                                  <h3 className="text-core-black text-lg font-medium">Top 5 by Premium</h3>
                                  <p className="text-core-grey text-base font-medium">
                                    Carrier, State, Line of Business
                                  </p>
                                </div>

                                <div className="w-2/5 flex justify-end">
                                  <span className="font-bold text-sm uppercase">All policies</span>
                                </div>

                                <div className="w-full mt-6 gap-4 flex">
                                  {tabsByPremium.map((tab, index) => (
                                    <button
                                      key={tab.label}
                                      onClick={() => handleTabClick(index)}
                                      className={`font-bold text-sm uppercase border-b-2 pb-1 transition ease-in ${
                                        index === activeTabIndex
                                          ? 'text-core-black border-core-black'
                                          : 'border-b-transparent hover:text-core-black hover:border-core-black'
                                      }`}
                                    >
                                      {tab.label}
                                    </button>
                                  ))}
                                </div>

                                <div className="w-full mt-4">{tabsByPremium[activeTabIndex].content}</div>
                              </div>

                              <div className="bg-white rounded-lg p-4 flex flex-wrap h-fit">
                                <div className="w-3/5 flex justify-start flex-col">
                                  <h3 className="text-core-black text-lg font-medium">Top 5 by Customers</h3>
                                  <p className="text-core-grey text-base font-medium">
                                    Carrier, State, Line of Business
                                  </p>
                                </div>

                                <div className="w-2/5 flex justify-end">
                                  <span className="font-bold text-sm uppercase">All policies</span>
                                </div>

                                <div className="w-full mt-6 gap-4 flex">
                                  {tabsByCustomer.map((tab, index) => (
                                    <button
                                      key={tab.label}
                                      onClick={() => handleTabCustomerClick(index)}
                                      className={`font-bold text-sm uppercase border-b-2 pb-1 transition ease-in ${
                                        index === activeCustomerTabIndex
                                          ? 'text-core-black border-core-black'
                                          : 'border-b-transparent hover:text-core-black hover:border-core-black'
                                      }`}
                                    >
                                      {tab.label}
                                    </button>
                                  ))}
                                </div>

                                <div className="w-full mt-4">{tabsByCustomer[activeCustomerTabIndex].content}</div>
                              </div>
                            </div>

                            <div className="w-full bg-white rounded-lg p-4 flex flex-wrap">
                              <div className="w-1/2 flex justify-start">
                                <h3 className="text-core-black text-lg font-medium">Top 10 Customers by Premium</h3>
                              </div>
                              <div className="w-1/2 flex justify-end">
                                <span className="font-bold text-sm uppercase">All policies</span>
                              </div>

                              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-24 mt-4">
                                <div>
                                  <table className="w-full">
                                    <thead className="text-core-grey font-normal text-xs text-left">
                                      <tr className="">
                                        <th className="w-[50px]">Name</th>
                                        <th className="w-[50px]">Premium</th>
                                        <th className="w-[50px]">%</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {topCustomers.slice(0, 4).map((item: any, index: number) => (
                                        <tr key={index} className=" border-b-[1px] border-gray-100 md:last:border-b-0">
                                          <td className="py-3">{item.customer_id}</td>
                                          <td className="py-3">{formatCurrency(item.dollars)}</td>
                                          <td className="py-3">{getPercentageWidth(item.percentage)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>

                                <div>
                                  <table className="w-full md:mt-0 -mt-6">
                                    <thead className="text-core-grey font-normal text-xs text-left md:contents hidden">
                                      <tr className="">
                                        <th className="w-[50px]">Name</th>
                                        <th className="w-[50px]">Premium</th>
                                        <th className="w-[50px]">%</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {topCustomers.slice(5, 9).map((item: any, index: number) => (
                                        <tr key={index} className="border-b-[1px] border-gray-100 last:border-b-0">
                                          <td className="w-[50px] py-3">{item.customer_id}</td>
                                          <td className="w-[50px] py-3">{formatCurrency(item.dollars)}</td>
                                          <td className="w-[50px] py-3">{getPercentageWidth(item.percentage)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="w-full bg-white rounded-lg p-4 flex flex-wrap">
                              <div className="w-1/2 flex justify-start flex-col">
                                <h3 className="text-core-black text-lg font-medium">Policy Breakdown</h3>
                                <p className="text-core-grey text-base font-medium">
                                  Payment Status, Payment Plan, Claims
                                </p>
                              </div>
                              <div className="w-1/2 flex justify-end">
                                <span className="font-bold text-sm uppercase">All policies</span>
                              </div>

                              <div className="w-full mt-6 gap-4 flex overflow-x-auto py-3 md:py-0">
                                {tabsByBreakDown.map((tab, index) => (
                                  <button
                                    key={tab.label}
                                    onClick={() => handleTabByBreackdowClick(index)}
                                    className={`font-bold text-sm uppercase whitespace-nowrap pb-1 border-b-2 transition ease-in ${
                                      index === activeTabByBreackDownIndex
                                        ? 'text-core-black border-core-black'
                                        : 'border-b-transparent hover:text-core-black hover:border-core-black'
                                    }`}
                                  >
                                    {tab.label}
                                  </button>
                                ))}
                              </div>

                              <div className="w-full mt-4">{tabsByBreakDown[activeTabByBreackDownIndex].content}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {!showTextMessage ? (
                <>
                  <div className="absolute rounded-b-lg bottom-0 border-t-[1px] bg-white border-[#C8C8C8] flex justify-end p-4 w-full gap-2">
                    <div>
                      <Button variant="fill" color="primary" onClick={handleContactSeller}>
                        Contact Seller
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="absolute rounded-b-lg bottom-0 border-t-[1px] bg-white border-[#C8C8C8] flex flex-wrap p-4 w-full gap-2">
                    <div className="w-full">
                      <TextareaComponent
                        height="h-32"
                        placeholderText="Add a short message"
                        maxCharacters={250}
                        showCharacterCount={true}
                        name="profileDescription"
                        value={messageContentInput}
                        onChange={handleMessageChange}
                      />
                    </div>

                    <div className="w-full flex justify-end">
                      <div>
                        <button
                          onClick={handleContactSeller}
                          className="py-2 px-4 text-core-black text-base font-medium"
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <Button
                          variant="fill"
                          color="secondary"
                          disabled={!isMessageValid}
                          onClick={handleSendContactRequest}
                        >
                          Send Request
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {alerts}
            </div>
          )}
        </div>
      ) : (
        <ModalPivotTable
          // sliceUid={sliceUid}
          onCloseModalPivotTable={handleOpenModalPivotTable}
          isOpen={showModalPivotTable}
          listingData={listingPolicyData}
        />
      )}
    </>
  );
};

export default FlyoutMatches;
