export type CommercialTypeListingOptionsType =
  | 'Commercial Auto'
  | 'Multiple Peril'
  | 'Accident & Health'
  | 'Aircraft'
  | 'Crop Federal'
  | 'Crop Private'
  | 'Farmowners'
  | 'Fidelity & Burglary/Crime'
  | 'Fire & Allied Lines'
  | 'Flood Federal'
  | 'Flood Private'
  | 'Inland Marine'
  | 'Life'
  | 'Medical Malpractice'
  | 'Property'
  | 'Surety'
  | 'Workers Comp'
  | 'Other Liability (Claims Made)'
  | 'Other Liability (Occurrence)';

export enum CommercialTypeListingOptions {
  auto = 'Commercial Auto',
  multiPeril = 'Multiple Peril',
  health = 'Accident & Health',
  aircraft = 'Aircraft',
  fedCrop = 'Crop Federal',
  priCrop = 'Crop Private',
  farm = 'Farmowners',
  fidelity = 'Fidelity & Burglary/Crime',
  fire = 'Fire & Allied Lines',
  fedFlood = 'Flood Federal',
  priFlood = 'Flood Private',
  inlandMarine = 'Inland Marine',
  life = 'Life',
  medicalMal = 'Medical Malpractice',
  surety = 'Surety',
  workersComp = 'Workers Comp',
  otherClaims = 'Other Liability (Claims Made)',
  otherOccurrence = 'Other Liability (Occurrence)',
}

export type PersonalTypeListingOptionsType =
  | 'Personal Auto'
  | 'Accident & Health'
  | 'Aircraft'
  | 'Burglary/Theft/Crime'
  | 'Fire & Allied Lines'
  | 'Flood Federal'
  | 'Flood Private'
  | 'Life'
  | 'Homeowners'
  | 'Inland Marine'
  | 'Other Liability (Claims Made)'
  | 'Other Liability (Occurrence)';

export enum PersonalTypeListingOptions {
  auto = 'Personal Auto',
  health = 'Accident & Health',
  aircraft = 'Aircraft',
  theft = 'Burglary/Theft/Crime',
  fire = 'Fire & Allied Lines',
  fedFlood = 'Flood Federal',
  priFlood = 'Flood Private',
  life = 'Life',
  home = 'Homeowners',
  inlandMarine = 'Inland Marine',
  otherClaims = 'Other Liability (Claims Made)',
  otherOccurrence = 'Other Liability (Occurrence)',
}

export enum CarrierListingOptions {
  abacus = 'Abacus Insurance - MGA',
  accendo = 'Accendo Insurance Company',
  acceptance = 'Acceptance Casualty Insurance Company',
  accredited = 'Accredited Specialty Insurance Company',
  acePC = 'ACE Property & Casualty Insurance Company',
  accelerantSpec = 'Accelerant Specialty Insurance Company',
  accidentFund = 'Accident Fund',
  admiral = 'Admiral Insurance',
  adriatic = 'Adriatic Insurance Company',
  advantage = 'Advantage Partners',
  aegis = 'Aegis Security Insurance Company',
  aegon = 'Aegon Ltd',
  aetna = 'Aetna',
  agency = 'Agency Insurance Company',
  aig = 'AIG',
  aimMutual = 'AIM Mutual Insurance',
  aipso = 'AIPSO',
  alabamaInsurance = 'Alabama Insurance Alliance',
  alfa = 'Alfa',
  alleghany = 'Alleghany Corporation',
  allianceNational = 'Alliance National Insurance Company',
  allianz = 'Allianz',
  allied = 'Allied Insurance Company of America',
  alliedPC = 'Allied Property & Casualty Insurance Company',
  alliedTrust = 'Allied Trust',
  allstate = 'Allstate',
  ambetter = 'Ambetter',
  americanbuilders = 'American Builders Insurance Company',
  americancontractor = 'American Contractors Insurance Company',
  americanEuropean = 'American European Insurance',
  americanFamily = 'American Family / The Main Street America Group',
  americanModern = 'American Modern Insurance Group Inc',
  americanNational = 'American National Insurance Company',
  americanStrategic = 'American Strategic Insurance',
  amerilife = 'Amerilife',
  ameritrust = 'Ameritrust',
  americanRisk = 'American Risk Insurance Company',
  amguard = 'AmGUARD Insurance Company',
  amtrust = 'AmTrust Insurance Company',
  amwins = 'Amwins',
  annover = 'Annover Companies - MGA',
  anthem = 'Anthem - Elevance Health',
  aon = 'AON - MGA',
  apogee = 'Apogee Insurance Group - MGA',
  appalachian = 'Appalachian Insurance Company',
  appalachianU = 'Appalachian Underwriters - MGA',
  appliedUnderwriters = 'Applied Underwriters - MGA',
  arbella = 'Arbella Mutual Insurance Company',
  arch = 'Arch Insurance Group',
  argoInterational = 'Argo Group International Holdings Ltd',
  arlingtonRoe = 'Arlington Roe - MGA',
  assocMutual = 'Associated Mutual Insurance',
  assuranceAmerica = 'AssuranceAmerica',
  assurant = 'Assurant',
  assuredPartners = 'Assured Partners - MGA',
  ascendant = 'Ascendant Underwriters',
  aspenHoldingsBermuda = 'Aspen Insurance Holdings Limited',
  aspera = 'Aspera Insurance',
  atlanticCasualty = 'Atlantic Casualty',
  atlanticSpecialty = 'Atlantic Specialty',
  atrio = 'Atrio Insurance Group',
  attainSpecialty = 'Atain Specialty Insurance Company',
  autoClub = 'Auto Club Enterprises',
  autoOwners = 'Auto-Owners Insurance',
  axaSA = 'AXA SA',
  axis = 'Axis',
  badger = 'Badger Mutual',
  bannerLife = 'Banner Life Insurance Company',
  bass = 'Bass Underwriters - MGA',
  beazley = 'Beazley Insurance Company',
  benchamrk = 'Benchmark Insurance Company',
  berkshire = 'Berkshire Hathaway',
  blueCross = 'Blue Cross & Blue Shield',
  branch = 'Branch',
  bristolWest = 'Bristol West',
  btis = 'Builders & Tradesmens Insurance Services - MGA',
  buildersMutual = 'Builders Mutual',
  burlington = 'Burlington Insurance Company',
  burnswil = 'Burns & Wilcox',
  cajun = 'Cajun Underwriters',
  calEarthquake = 'California Earthquake Authority',
  calFairPlan = 'California Fair Plan',
  canal = 'Canal',
  capitolSpecial = 'Capitol Special Risks - MGA',
  cardiganGen = 'Cardigan General - MGA',
  carolinaCas = 'Carolina Casualty Insurance Company',
  cbic = 'CBIC',
  centralMutual = 'Central Mutual Insurance Company',
  centurySurety = 'Century Surety',
  charityFirst = 'Charity First Insurance Services - MGA',
  charterOak = 'Charter Oak Fire Insurance Company',
  chelsea = 'Chelsea',
  chesapeakeEmployers = 'Chesapeake Employers Insurance Company',
  chubb = 'Chubb',
  churchMut = 'Church Mutual Insurance Company',
  cigna = 'Cigna',
  cincinnati = 'Cincinnati Financial',
  citizens = 'Citizens Property',
  clearblue = 'Clear Blue Specialty',
  clearcover = 'Clearcover Insurance',
  clearSpring = 'Clear Spring Property and Casualty Insurance',
  cna = 'CNA',
  coaction = 'Coaction',
  coalition = 'Coalition Insurance',
  columbiaLloyds = 'Columbia Lloyds',
  compsource = 'Compsource Mutual Insurance Company',
  concert = 'Concert Specialty Insurance Company',
  cornerstoneNational = 'Cornerstone National',
  countryFinancial = 'Country Financial',
  covertree = 'CoverTree',
  coverwhale = 'Coverwhale - MGA',
  crumbForsterIndemnity = 'Crum & Forster Indemnity Company',
  crump = 'Crump Life Insurance Services',
  csaa = 'CSAA Insurance Group',
  cse = 'CSE Insurance Group',
  dairylandIns = 'Dairyland Insurance Company',
  db = 'DB Insurance',
  deltaDental = 'Delta Dental',
  donegal = 'Donegal',
  dryden = 'Dryden Mutual',
  elephant = 'Elephant Insurance Company',
  embarkGeneral = 'Embark General - MGA',
  emersonRogers = 'Emerson Rogers - MGA',
  employersHoldings = 'Employers Holdings',
  emc = 'Employers Mutual Casualty',
  employersPreferred = 'Employers Preferred Insurance Company',
  encompassInsCoAmer = 'Encompass Insurance Company Of America',
  enstar = 'Enstar Group',
  erie = 'Erie Insurance',
  essentia = 'Essentia Insurance Company',
  evanston = 'Evanston Insurance',
  everest = 'Everest RE Group',
  evolutionBrokers = 'Evolution Insurance Brokers - MGA',
  evolution = 'Evolution Risk Advisors',
  executiveRisk = 'Executive Risk Indemnity Inc',
  factoryMutual = 'Factory Mutual Insurance Company',
  fairfax = 'Fairfax Financial',
  fallsLake = 'Falls Lake National Insurance Company',
  farmers = 'Farmers Insurance',
  farmersMutualFire = 'Farmers Mutual Fire Insurance Company',
  fidelity = 'Fidelity and Guaranty Insurance Company',
  firstConnect = 'First Connect - MGA',
  floridaPeninsula = 'Florida Peninsula',
  forge = 'Forge Insurance Company',
  founders = 'Founders',
  fmGlobal = 'FM Global',
  foremostPC = 'Foremost Property And Casualty Insurance Company',
  foremostSig = 'Foremost Signature Insurance Company',
  franklinMutual = 'Franklin Mutual Insurance Company',
  frederick = 'Frederick Mutual Insurance Company',
  gatewaySpecialty = 'Gateway Specialty Insurance - MGA',
  geoVera = 'GeoVera',
  general = 'General Insurance Company of America',
  generalStar = 'General Star Indemnity Company',
  germania = 'Germania Insurance Group',
  glatfelter = 'Glatfelter Insurance Company',
  globalIndemnity = 'Global Indemnity Group',
  graphicArts = 'Graphic Arts Mutual Insurance Company',
  grange = 'Grange Insurance',
  greatAmerican = 'Great American Insurance Group',
  greatLakes = 'Great Lakes Mutual Insurance Company',
  greatNorthwest = 'Great Northwest Insurance Company',
  greaterNYMut = 'Greater New York Mutual Insurance Company',
  greenville = 'Greenville Casualty Insurance Company',
  grinnell = 'Grinnell Mutual Reinsurance Company',
  groupHealth = 'Group Dental Health Administrators',
  guaranteeTrust = 'Guarantee Trust Life Insurance Company',
  guardianLife = 'Guardian Life Insurance Company Of America',
  guideOneIns = 'GuideOne Insurance Company',
  theHartford = 'The Hartford',
  haulers = 'Haulers Insurance Company',
  hagerty = 'Hagerty',
  heartlandNational = 'Heartland National Life',
  heritage = 'Heritage Insurance Holdings Group',
  heritagePC = 'Heritage Property & Casualty Insurance',
  hippo = 'Hippo Insurance Services - MGA',
  hiscox = 'Hiscox Insurance Company Inc.',
  homeownersAmerica = 'Homeowners of America Insurance',
  homeownersChoice = 'Homeowners Choice',
  homesiteInsurance = 'Homesite Insurance Company',
  houstonspec = 'Houston Specialty Insurance Company',
  hudson = 'Hudson Insurance Company',
  hullCo = 'Hull & Company - MGA',
  humana = 'Humana',
  icwGroup = 'ICW Group',
  imt = 'IMT Insurance',
  infinityAuto = 'Infinity Auto Insurance Company',
  insurmark = 'Insurmark - MGU',
  intact = 'Intact Financial Corporation',
  integon = 'Integon',
  integrity = 'Integrity Insurance',
  ifg = 'International Financial Group',
  irvin = 'Irvin Green and Associates',
  jacksonSumner = 'Jackson Sumner - MGA',
  jamesRiverIns = 'James River Insurance Company',
  jencap = 'Jencap - MGA',
  jhFerguson = 'J.H. Ferguson & Associates',
  kemper = 'Kemper',
  kinsale = 'Kinsale Insurance Company',
  leatherstock = 'Leatherstocking Cooperative',
  lemonade = 'Lemonade Insurance Company',
  libertyMutual = 'Liberty Mutual',
  lincolnNational = 'Lincoln National Life Insurance Company',
  lloyds = 'Lloyds',
  lockton = 'Lockton',
  louisianaCitizens = 'Louisiana Citizens Property',
  mainStreetAmerica = 'Main Street America',
  madisonMutual = 'Madison Mutual',
  manhattanLife = 'Manhattan Life',
  mapfre = 'MAPFRE',
  markel = 'Markel',
  marshMcLennan = 'Marsh & McLennan Companies',
  medicalProMutual = 'Medical Professional Mutual Insurance Company',
  medico = 'Medico',
  merchantsBonding = 'Merchants Bonding Company',
  merchants = 'Merchants Insurance Group',
  mercury = 'Mercury Insurance',
  mesa = 'Mesa Underwriters Specialty Insurance Company',
  metLife = 'MetLife',
  method = 'Method Insurance - MGA',
  mgaInsCo = 'MGA Insurance Company',
  molina = 'Molina Healthcare',
  monarch = 'Monarch',
  morstan = 'Morstan - MGA',
  mountVernonFire = 'Mount Vernon Fire Insurance Company',
  mgt = 'MGT Insurance Company',
  munich = 'Munich RE',
  mutualOmaha = 'Mutual of Omaha',
  nationalFlood = 'National Flood Insurance Program',
  nationalGeneral = 'National General',
  nationalHealth = 'National Health Insurance Company',
  nationalLegacy = 'National Legacy Insurance Company',
  nationwide = 'Nationwide',
  nautilus = 'Nautilus Insurance Company',
  ncci = 'NCCI',
  neptuneFlood = 'Neptune Flood',
  ngm = 'NGM Insurance Company',
  njManufacturers = 'New Jersey Manufacturers Insurance Company',
  nyCentral = 'New York Central Mutual Fire Insurance Company',
  nyProperty = 'New York Property Insurance Underwriting Association',
  next = 'Next Insurance Company',
  nonprofits = 'Nonprofits Insurance Alliance',
  northAmericanCasualty = 'North American Casualty Group',
  northCountry = 'North Country Insurance',
  northwestern = 'Northwestern Life',
  novatae = 'Novatae - MGA',
  nsl = 'NSL - MGA',
  nycm = 'NYCM Insurance Group',
  nysif = 'NYSIF',
  obie = 'Obie Insurance',
  obsidian = 'Obsidian Insurance Company',
  occidental = 'Occidental Insurance',
  ohio = 'Ohio Casualty',
  oldRepublic = 'Old Republic Insurance Group',
  omega = 'Omega - MGA',
  openly = 'Openly Insurance Company',
  orange = 'Orange Insurance Exchange',
  orchid = 'Orchid Insurance',
  oregonMutual = 'Oregon Mutual',
  orion = 'Orion180',
  oscar = 'Oscar',
  owners = 'Owners Insurance Company',
  pasificSource = 'PacificSource',
  pacificSpecialty = 'Pacific Specialty',
  palisadesSafety = 'Palisades Safety and Insurance',
  palomar = 'Palomar',
  pathpoint = 'Pathpoint',
  pekin = 'Pekin',
  pennLummbermens = 'Penn Lumbermens Mutual',
  pennNational = 'Penn National',
  pennAmerica = 'Penn-America',
  pennStar = 'Penn-Star',
  permanentGeneral = 'Permanent General Assurance Corporation',
  philadelphiaContribute = 'The Philadelphia Contributionship',
  philadelphia = 'Philadelphia Insurance Companies',
  phoenix = 'Phoenix Insurance Company',
  pie = 'Pie Casualty Insurance',
  pinnacol = 'Pinnacol Assurance',
  platteRiver = 'Platte River Insurance',
  plymouthRock = 'Plymouth Rock Assurance',
  preferredEmployers = 'Preferred Employers Insurance Company',
  principalLife = 'Principal Life Insurance Company',
  privilegeUnderwriters = 'Privilege Underwriters Reciprocal Exchange',
  physiciansReciprocal = 'PRI',
  primerica = 'Primerica Inc',
  proassurance = 'ProAssurance Specialty Insurance',
  progressive = 'Progressive',
  propeller = 'Propeller Bonds - MGA',
  protectiveLife = 'Protective Life Insurance Company',
  proWriters = 'ProWriters',
  pureSpecialty = 'Pure Specialty Exchange',
  qbe = 'QBE',
  quincyMutFire = 'Quincy Mutual Fire',
  regence = 'Regence',
  republicFranklin = 'Republic Franklin Insurance Company',
  rhp = 'RHP General Agency',
  riskplacement = 'Risk Placement Services',
  rivingtonPartners = 'Rivington Partners - MGA',
  rla = 'RLA Insurance Intermediaries - MGA',
  rli = 'RLI Insurance',
  rockford = 'Rockford Mutual',
  rockingham = 'Rockingham Insurance Company',
  rtSpecialty = 'RT Specialty - MGA',
  safeco = 'Safeco Insurance Company Of America',
  safepoint = 'SafePoint',
  safety = 'Safety Insurance Group',
  safeway = 'Safeway',
  sagesure = 'SageSure Insurance Managers - MGA',
  saif = 'SAIF',
  scottsdale = 'Scottsdale Insurance Company',
  scorGlobal = 'SCOR Global Life Americas',
  seabright = 'Seabright Insurance Company',
  secura = 'Secura',
  securityNatl = 'Security National Insurance',
  securityFirst = 'Securit First Insurance',
  selective = 'Selective',
  seneca = 'Seneca Insurance',
  sentara = 'Sentara',
  sequoia = 'Sequoia Insurance',
  sfmMutual = 'SFM Mutual',
  shelterpoint = 'Shelterpoint',
  simon = 'Simon Agency - MGA',
  silverScript = 'SilverScript',
  sirius = 'SiriusPoint America Insurance Company',
  sisinsure = 'SIS Insure',
  sky = 'Sky',
  slide = 'Slide Insurance',
  society = 'Society Insurance',
  sompoAmerica = 'Sompo America Insurance Company',
  southernOak = 'Southern Oak Insurance Company',
  southernSpecialty = 'Southern Specialty Underwriters',
  southernTrust = 'Southern Trust Insurance Company',
  specialtyIns = 'Specialty Insurance Services',
  spinnaker = 'Spinnaker Insurance Company',
  starr = 'Starr Indemnity & Liability',
  stateAuto = 'State Auto Property & Casualty Insurance Company',
  stateComp = 'State Compensation Insurance Fund',
  stateInsFund = 'State Insurance Fund Workers Compensation',
  stateNational = 'State National Insurance Company',
  stateFarm = 'State Farm',
  statecompfund = 'State Compensation Insurance Fund',
  starrindem = 'Starr Indemnity & Liability',
  steadpoint = 'SteadPoint Insurance Company',
  sterling = 'Sterling Insurance',
  stillwater = 'Stillwater Insurance',
  swissreAm = 'Swiss Re Solutions America',
  swissreEl = 'Swiss Re Solutions Elite',
  swyfft = 'Swyfft - MGA',
  tapco = 'Tapco Underwriters - MGA',
  tangram = 'Tangram Insurance Services',
  techIns = 'Technology Insurance Co',
  texasFair = 'Texas Fair Plan',
  texasFarmBureau = 'Texas Farm Bureau Insurance',
  texasInsurance = 'Texas Insurance Company',
  texasMutualIns = 'Texas Mutual Insurance',
  texasWindstorm = 'Texas Windstorm Insurance',
  theHanover = 'The Hanover Insurance Group',
  tokioMarine = 'Tokio Marine',
  towerHill = 'Tower Hill',
  transAmerica = 'Transamerica',
  travelers = 'Travelers',
  trelisIns = 'Trexis Insurance Corporation',
  trelisOne = 'Trexis One Insurance Corporation',
  trisura = 'Trisura Specialty',
  typTap = 'TypTap',
  underwritersLloyds = 'Underwriters At Lloyds London',
  umpcHealth = 'UMPC Health',
  unionMutual = 'Union Mutual Fire Insurance Company',
  unionmutualf = 'Union Mutual Fire Insurance Company',
  unionSecurity = 'Union Security Insurance Company',
  unique = 'Unique Insurance Company',
  unitedAuto = 'United Automobile Insurance Company',
  unitedHealthcare = 'United Healthcare',
  unitedNational = 'United National Insurance Company',
  unitedSpecialty = 'United Specialty Insurance Company',
  universalCasualty = 'Universal Fire & Casualty Insurance',
  universalGroup = 'Universal Group Inc',
  universalHoldings = 'Universal Insurance Holdings',
  upcic = 'United P&C Insurance Company',
  usaa = 'USAA',
  usassure = 'US Assure',
  usg = 'USG Insurance Services',
  usLiability = 'United States Liability',
  usPlateGlass = 'USPlate Glass Insurance Company',
  usCoastal = 'US Coastal Property & Casualty Insurance Company',
  usSpecialty = 'US Specialty Insurance Company',
  uticaFirst = 'Utica First Insurance Company',
  uticaMutual = 'Utica Mutual Insurance Company',
  vermontMutual = 'Vermont Mutual Insurance Company',
  vgm = 'VGM',
  wellcare = 'Wellcare',
  wellington = 'Wellington Risk Insurance Agency - MGA',
  wesco = 'Wesco Insurance Company',
  westBend = 'West Bend Insurance Company',
  westguard = 'Westguard Insurance Company',
  westernSurety = 'Western Surety',
  westernWorld = 'Western World Insurance Company',
  wilshire = 'Wilshire Insurance Company',
  wisconsinMutual = 'Wisconsin Mutual',
  woodlands = 'The Woodlands Insurance Company',
  workpartners = 'Workpartners National Inc',
  wright = 'Wright National Flood',
  wrBerkley = 'WR Berkley',
  zenith = 'Zenith Insurance',
  zurich = 'Zurich Insurance',
  other = 'Other',
  otherMGA = 'Other - MGA',
}

export const CommercialListValues = [
  { code: 'AUTOB', description: 'Commercial Auto' },
  { code: 'PROP', description: 'Commercial Property' },
  { code: 'BOP', description: 'Business Owners Policy (BOP)' },
  { code: 'ACCTC', description: 'Accident' }, // made up code
  { code: 'ALIAB', description: 'Airport Liability' },
  { code: 'AVIAC', description: 'Aviation' },
  { code: 'BANDM', description: 'Boiler and Machinery' },
  { code: 'BSMBP', description: 'Boiler and Machinery Small Business' },
  { code: 'BLDRK', description: 'Builders Risk' },
  { code: 'BURG', description: 'Burglary' },
  { code: 'BOPPR', description: 'Business Owners Low Risk' },
  { code: 'BOPGL', description: 'Business Owners and General Liability' },
  { code: 'CARGO', description: 'Cargo Marine' },
  { code: 'COMAR', description: 'Commercial Articles' },
  { code: 'CRIM', description: 'Crime' },
  { code: 'CROP', description: 'Crop and Hail' },
  { code: 'CYBER', description: 'Cyber Liability' },
  { code: 'CYBR', description: 'Cyber Liability and Privacy' },
  { code: 'DIC', description: 'Difference in Conditions' },
  { code: 'DBL', description: 'Directors and Officers' },
  { code: 'EQKC', description: 'Earthquake - Commercial' },
  { code: 'EQ', description: 'Earthquake' },
  { code: 'EDP', description: 'Electronic Data Processing' },
  { code: 'EPLI', description: 'Employers Practice Liability' },
  { code: 'ELIAB', description: 'Employment Liability' },
  { code: 'EO', description: 'Errors and Omissions' },
  { code: 'EANDO', description: 'Errors and Omissions' },
  { code: 'CONTR', description: 'Equipment Floater' },
  { code: 'XLIB', description: 'Excess Liability' },
  { code: 'FARM', description: 'Farm Agriculture' },
  { code: 'FIDFG', description: 'Fidelity and Forgery' },
  { code: 'FIDU', description: 'Fiduciary Liability' },
  { code: 'FINAR', description: 'Fine Arts' },
  { code: 'CFIRE', description: 'Fire' },
  { code: 'FLOOD', description: 'Flood' },
  { code: 'GARAG', description: 'Garage and Dealers' },
  { code: 'GKP', description: 'Garage Keepers' },
  { code: 'CGL', description: 'General Liability' },
  { code: 'GLASS', description: 'Glass - Commercial' },
  { code: 'GRDEN', description: 'Group Dental' },
  { code: 'GRDIS', description: 'Group Disability' },
  { code: 'GRLIF', description: 'Group Life' },
  { code: 'GRMED', description: 'Group Medical' },
  { code: 'INMRC', description: 'Inland Marine' },
  { code: 'HULL', description: 'Inland Water Hull' },
  { code: 'INTER', description: 'Internet Cyber and Privacy Liability' },
  { code: 'KIDRA', description: 'Kidnap and Ransom' },
  { code: 'LL', description: 'Liquor Liability' },
  { code: 'MANFR', description: 'Manufactures Output' },
  { code: 'MEDPR', description: 'Medical / Professional Liability' },
  { code: 'MOS', description: 'Mercantile Open Stock' },
  { code: 'CYCL', description: 'Motorcycle' },
  { code: 'CYCLE', description: 'Motorcycle' },
  { code: 'MHOME', description: 'Mobile Home' },
  { code: 'MH', description: 'Motorhome' },
  { code: 'MUCIS', description: 'Musical Instruments' },
  { code: 'OCEAN', description: 'Ocean Marine' },
  { code: 'CPKGE', description: 'Package' },
  { code: 'PENPS', description: 'Pension / Profit Sharing Plan' },
  { code: 'PHYS', description: 'Physicians and Surgeons' },
  { code: 'PROF', description: 'Professional Liability' },
  { code: 'PI', description: 'Protection and Indemnity' },
  { code: 'PRDCT', description: 'Product Liability' },
  { code: 'RECV', description: 'Recreational Vehicles' },
  { code: 'REIN', description: 'Reinsurance' },
  { code: 'ROBB', description: 'Robbery' },
  { code: 'SCHPR', description: 'Scheduled Property' },
  { code: 'SEMIN', description: 'Seminar' },
  { code: 'SIGNS', description: 'Signs' },
  { code: 'SMP', description: 'Special Multi-Peril' },
  { code: 'SURE', description: 'Surety' },
  { code: 'TRANS', description: 'Transportation' },
  { code: 'TRKRS', description: 'Truckers' },
  { code: 'CUMBR', description: 'Umbrella' },
  { code: 'VALP', description: 'Valuable Papers' },
  { code: 'VPROP', description: 'Vacant Property' },
  { code: 'BOATC', description: 'Watercraft - Commercial' },
  { code: 'WORK', description: 'Workers Compensation' },
  { code: 'OTHER', description: 'Other' },
];

export const PersonalListValues = [
  { code: 'HOME', description: 'Homeowners' },
  { code: 'AUTOP', description: 'Personal Auto' },
  { code: 'ACCTP', description: 'Accident' }, // Made-up code
  { code: 'ANN', description: 'Annunity' },
  { code: 'AVIAP', description: 'Aviation' },
  { code: 'CPL', description: 'Comprehensive Personal Liability' },
  { code: 'CONDO', description: 'Condominium' },
  { code: 'DVH', description: 'Dental Vision Hearing' },
  { code: 'DFIRE', description: 'Dwelling Fire' },
  { code: 'EQKP', description: 'Earthquake' },
  { code: 'ELIAB', description: 'Employment Liability' },
  { code: 'FLOOD', description: 'Flood' },
  { code: 'HOSPIND', description: 'Hospital Indemnity' },
  { code: 'INDEN', description: 'Individual Dental' },
  { code: 'INDIS', description: 'Individual Disability' },
  { code: 'INHEA', description: 'Individual Health' },
  { code: 'INLIF', description: 'Individual Life' },
  { code: 'INMRP', description: 'Inland Marine - Personal' },
  { code: 'HEALTH', description: 'Health' },
  { code: 'MEDA', description: 'Medicare Advantage' },
  { code: 'MEDPDP', description: 'Medicare Prescription Drug' }, // Made-up code
  { code: 'MEDS', description: 'Medicare Supplement' },
  { code: 'MHOME', description: 'Mobile Home' },
  { code: 'CYCLE', description: 'Motorcycle' },
  { code: 'MHO', description: 'Motorhome' },
  { code: 'PPKGE', description: 'Package' },
  { code: 'PL', description: 'Personal Liability' },
  { code: 'PLMSC', description: 'Personal Liability Miscellaneous' },
  { code: 'RECV', description: 'Recreational Vehicles' },
  { code: 'RENT', description: 'Renters' },
  { code: 'RGLASS', description: 'Residence Glass' },
  { code: 'PTHFT', description: 'Theft' },
  { code: 'TT', description: 'Travel Trailer' },
  { code: 'PUMBR', description: 'Umbrella' },
  { code: 'BOAT', description: 'Watercraft - Personal' },
  { code: 'OTHER', description: 'Other' },
];

export enum UsStatesOptionsShort {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum UsStatesOptions {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DC = 'District of Columbia',
  DE = 'Delaware',
  FL = 'Florida',
  GA = 'Georgia',
  GU = 'Guam',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  PR = 'Puerto Rico',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
}

export const UsStatesOptionsArray = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'District of Columbia',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];
