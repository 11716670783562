import React, { useEffect, useState } from 'react';
import cakeLogo from '../../../styles/assets/logos/cake-logo-black.png';
import Button from 'src/app/shared/components/Buttons/Button';
import Input from 'src/app/shared/components/Inputs/Input';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { Form, Formik } from 'formik';
import { confirmSignUp, resendConfirmEmail } from 'src/api/user';

interface ConfrimProps {
  isLoading: boolean;
  isUserLoggedIn: boolean;
}

interface RedirectLocationState {
  referrer: Location;
}

const ConfirmAccountV2 = (props: ConfrimProps): React.ReactElement => {
  const { isLoading, isUserLoggedIn } = props;
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { alerts, createToast } = useAlert();
  const [confirmEmail] = useState(localStorage.getItem('confirmUserEmail') || '');
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [, setIsPendingButton] = useState(false);

  const initialValues = {
    email: confirmEmail || '',
    code: '',
  };

  const onSubmit = async (values: any) => {
    setIsPendingButton(true);
    try {
      setIsPendingButton(true);
      const response = await confirmSignUp(values.email, values.code);
      if (response.message) {
        window.location.href = '/slices';
      } else {
        createToast({ text: response.message, variant: 'danger' });
      }
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Invalid verification code provided, please try again.',
        variant: 'danger',
      });
    }
    setIsPendingButton(false);
  };

  const handleResendEmail = async (confirmEmail: string) => {
    try {
      const response = await resendConfirmEmail(confirmEmail);
      if (response.message !== '') {
        createToast({ text: response.message, variant: 'success' });
      } else {
        createToast({
          text: 'Invalid verification code provided, please try again.',
          variant: 'danger',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isLoading && isUserLoggedIn) {
      if (locationState) {
        const { referrer } = locationState as RedirectLocationState;
        history.push(`${referrer.pathname}${referrer.search}`);
      } else {
        history.push('/slices');
      }
    }
  }, [isLoading, isUserLoggedIn, history]);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
      {({ values, handleChange }) => (
        <>
          <Form className="h-fit w-full min-h-screen bg-white">
            <div className="w-full h-full top-0 absolute">
              <div className="w-full top-0">
                <div className="float-left py-4 px-10">
                  <img src={cakeLogo} alt="Cake logo" className="w-[60px] h-[24]" />
                </div>
              </div>

              <div className="w-full md:w-1/2 mx-auto my-40 space-y-3">
                <h1 className="text-xl md:text-5xl font-medium text-center">Verify your email address</h1>

                <p className="font-medium text-sm md:text-base text-core-grey">
                  A verification code has been sent to <strong className="text-core-black">{confirmEmail}</strong>.{' '}
                  <br />
                  Please enter below.
                </p>

                <div className="w-1/2 mx-auto flex pt-8 space-y-2 flex-col">
                  {!confirmEmail && (
                    <Input
                      type="email"
                      labelInput="Email"
                      placeholder="Enter your email"
                      isRequiredInput={true}
                      name="email"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={values.email || ''}
                    />
                  )}
                  <Input
                    type="text"
                    labelInput="verification code"
                    placeholder="Enter your code"
                    isRequiredInput={true}
                    name="code"
                    maxlength={6}
                    onChange={(e) => {
                      handleChange(e);
                      setButtonsDisabled(false);
                    }}
                    value={values.code || ''}
                  />
                </div>

                <div className="w-56 mx-auto my-4">
                  <Button variant="fill" color="primary" type="submit" disabled={buttonsDisabled}>
                    Verify
                  </Button>
                </div>

                <div className="w-fit mx-auto mt-3">
                  <Button variant="outline" color="secondary" onClick={() => handleResendEmail(confirmEmail)}>
                    Resend Email
                  </Button>
                </div>
              </div>
            </div>
            {alerts}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ConfirmAccountV2;
