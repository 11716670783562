import React, { FC, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { Form, Formik } from 'formik';
import Input from 'src/app/shared/components/Inputs/Input';
import Button from 'src/app/shared/components/Buttons/Button';
import { ValuationLineItem, ValuationReq } from 'src/types/bookValuations';
import { postBookValuation } from 'src/api/valuation';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';

const formatCurrency = (value: number): string => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

interface BookValuationWidgetProps {
  policies: any[];
}

const BookValuationWidget: FC<BookValuationWidgetProps> = ({ policies }) => {
  const [valuationSummaryOpen, setValuationSummaryOpen] = useState(true);
  const { alerts, createToast } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [assumedComission, setAssumedComission] = useState('');
  const [uniqueCustomers, setUniqueCustomers] = useState('');
  const [valuationItemsValues, setValuationItemsValues] = useState<
    {
      amount: string;
      commission: string;
      revenueEstimate: string;
    }[]
  >([]);

  const initialValues = {
    assumedCommission: assumedComission,
    uniqueCustomers: uniqueCustomers,
  };

  const handleOpenValuationSummary = () => {
    setValuationSummaryOpen(!valuationSummaryOpen);
  };

  const valuationItems: ValuationLineItem[] = policies.map((item: any) => {
    const typeOfInsurance: string = item.type_of_insurance ?? '';
    const lineOfBusiness: string = item.line_of_business ?? '';
    return {
      state: item.insured_state,
      lob: `${typeOfInsurance} ${lineOfBusiness}`,
      carrier: item.carrier,
      annualPremium: item.total_premium,
      numPolicies: 1,
      numCustomers: 1,
      overrideCommission: (assumedComission ? parseFloat(assumedComission) : 0) / 100,
    };
  });

  const handleGetBookValuation = async () => {
    setIsLoading(true);

    try {
      const emptyValuationReq: ValuationReq = {
        uniqueCustomers: uniqueCustomers ? parseFloat(uniqueCustomers) : 0,
        lineItems: valuationItems,
      };
      const response = await postBookValuation(emptyValuationReq);
      if (response) {
        const { low, mid, high, assumedRevenue, assumedCommission } = response;
        const newValues = [
          {
            amount: formatCurrency(Math.round(assumedRevenue)),
            commission: `${assumedCommission}%`,
            revenueEstimate: 'revenue estimate',
          },
          {
            amount: formatCurrency(Math.round(parseFloat(low.dollar))),
            commission: `${low.multiple}x`,
            revenueEstimate: 'low',
          },
          {
            amount: formatCurrency(Math.round(parseFloat(mid.dollar))),
            commission: `${mid.multiple}x`,
            revenueEstimate: 'medium',
          },
          {
            amount: formatCurrency(Math.round(parseFloat(high.dollar))),
            commission: `${high.multiple}x`,
            revenueEstimate: 'high',
          },
        ];

        setValuationItemsValues(newValues);
      }
    } catch (error) {
      createToast({
        text: 'Error get book valuations',
        variant: 'danger',
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookValuation();
  }, []);

  const onSubmit = async (values: any) => {
    setAssumedComission(values.assumedCommission);
    setUniqueCustomers(values.uniqueCustomers);
    createToast({ text: 'Your configuration is saved successfully', variant: 'success' });
    handleGetBookValuation();
  };

  return (
    <div className="w-full rounded-[8px] p-3 border text-left">
      <div className="w-full flex cursor-pointer" onClick={handleOpenValuationSummary}>
        <div className="w-10/12 flex justify-start">
          <h3 className="p-0 m-0 text-core-black font-medium text-lg">Valuation Summary</h3>
        </div>

        <div className="w-2/12 flex justify-end">
          {valuationSummaryOpen ? (
            <ChevronUpIcon className="h-4 w-4 my-auto text-black" />
          ) : (
            <ChevronDownIcon className="h-4 w-4 my-auto text-black" />
          )}
        </div>
      </div>

      {valuationSummaryOpen && (
        <div className="w-full flex flex-col">
          <>
            {isLoading ? (
              <Skeleton type="bookValuationWidget" />
            ) : (
              <>
                <div className="w-full">
                  {valuationItemsValues.length > 0 && (
                    <>
                      <p className="uppercase text-gray-600 font-bold py-3 text-xs p-0 m-0">
                        {valuationItemsValues[0]?.revenueEstimate}
                      </p>
                      <h4 className="text-core-black font-medium text-5xl">
                        {valuationItemsValues[0]?.amount !== undefined &&
                        !isNaN(parseFloat(valuationItemsValues[0]?.amount))
                          ? parseFloat(valuationItemsValues[0]?.amount).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })
                          : valuationItemsValues[0]?.amount}
                      </h4>
                    </>
                  )}
                </div>
                <div className="w-full grid grid-cols-3 gap-2 mt-3 text-left">
                  {valuationItemsValues.slice(1).map((item, index) => (
                    <div className="border rounded-[4px] p-3" key={index}>
                      <h4 className="text-core-black text-xl font-bold truncate max-w-[170px]">
                        {item.amount !== undefined && !isNaN(parseFloat(item.amount))
                          ? parseFloat(item.amount).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })
                          : item.amount}
                      </h4>
                      <div className="w-full mt-3">
                        <p className="p-0 m-0 text-sm font-medium">{item.commission}</p>
                        <p className="p-0 m-0 text-2xs text-gray-600 font-bold uppercase">{item.revenueEstimate}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="w-full flex flex-col mt-4">
                  <Formik className="w-full" initialValues={initialValues} onSubmit={onSubmit}>
                    {({ values, handleChange }) => (
                      <>
                        <Form className="w-full">
                          <div className="grid grid-cols-1 space-y-3 md:space-y-0 md:grid-cols-2 gap-2">
                            <Input
                              type="text"
                              labelInput="Commission Rate Override"
                              placeholder="Enter commission rate (%)"
                              isRequiredInput={false}
                              name="assumedCommission"
                              onChange={(e) => {
                                handleChange(e);
                                setAssumedComission(e.target.value);
                              }}
                              value={values.assumedCommission || ''}
                            />

                            <Input
                              type="text"
                              labelInput="Unique Customers Override"
                              placeholder="Enter unique customers"
                              isRequiredInput={false}
                              name="uniqueCustomers"
                              onChange={(e) => {
                                handleChange(e);
                                setUniqueCustomers(e.target.value);
                              }}
                              value={values.uniqueCustomers || ''}
                            />
                          </div>

                          <div className="w-full mt-2">
                            <Button variant="fill" color="secondary" type="submit">
                              Apply
                            </Button>
                          </div>
                          {alerts}
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default BookValuationWidget;
