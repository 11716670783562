import React, { useState, useEffect, useRef } from 'react';
import cakeIntegration from '../../../../styles/assets/logos/Illustration.png';
import { Button } from 'src/app/shared/components/Buttons/Button';
import { useGetIntegrationsData } from 'src/hooks/useIntegrations';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import hawkSoftLogo from 'src/styles/assets/logos/hawksoftLogo.svg';
import { formatLastSync } from 'src/app/shared/utils/formatters';
import { ReactComponent as GitPull } from '../../../../styles/assets/icons/outline/gitPull.svg';
import { ReactComponent as WarningIcon } from '../../../../styles/assets/icons/outline/warningIcon.svg';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import Input from 'src/app/shared/components/Inputs/Input';
import { Form, Formik } from 'formik';
import ModalConfirmDisconnect from './ModalConfirmDisconnect';
import { patchIntegration } from 'src/api/integrations';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import UploadModal from 'src/app/book-management/book-tabs/components/uploadModal';
import ModalManageMapping from './ModalManageMapping';
import { useGetMapFields } from 'src/hooks/useGetMapFields';

enum IntegrationStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

const Integrations = () => {
  const { data: integrationData, isPending, refetch } = useGetIntegrationsData();
  const { data: fieldsData, refetch: refetchMap } = useGetMapFields();
  const [showMoreOption, setShowMoreOption] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { alerts, createToast } = useAlert();
  const gitPull = <GitPull className="my-auto w-4 h-4" />;
  const warningIcon = <WarningIcon className="my-auto w-4 h-4" />;
  const [isModalUploadOpen, setisModalUploadOpen] = useState(false);
  const [openModalManageMapping, setOpenModalManageMapping] = useState(false);

  const carrierListWithIndicator = (fieldsData?.carrier || []).map((item) => ({ ...item, indicator: 'CARRIER' }));
  const lobListWithIndicator = (fieldsData?.lob || []).map((item) => ({ ...item, indicator: 'LINE' }));

  const combinedList = [...carrierListWithIndicator, ...lobListWithIndicator];

  const mappedItems = combinedList.filter((item) => item.mapped);
  const unmappedItems = combinedList.filter((item) => !item.mapped);

  const handleOpenMoreOptions = () => {
    setShowMoreOption(!showMoreOption);
  };

  const closeModal = (resetForm: () => void) => {
    setShowConfirmationModal(false);
    handleCancelAction(resetForm);
  };

  const handleCloseModalMapping = () => {
    setOpenModalManageMapping(false);
    refetchMap();
    refetch();
  };

  const onCloseModalIntegration = () => {
    setisModalUploadOpen(false);
  };

  const handleUpload = () => {
    setisModalUploadOpen(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        buttonRef.current &&
        menuRef.current &&
        !buttonRef.current.contains(event.target) &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMoreOption(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const initialValues = {
    ams: integrationData?.integration?.system_name || '',
    amsCode: integrationData?.integration ? integrationData.integration.reference_id : '',
    status: integrationData?.integration?.status || '',
    uid: integrationData?.integration?.uid || '',
  };

  const onSubmit = async (values: any) => {
    try {
      await patchIntegration(values.amsCode, values.ams, 'AMS', values.status, values.uid);
      createToast({
        text: 'updated account successfully',
        variant: 'success',
      });
      refetch();
      setButtonsDisabled(true);
    } catch (error) {
      console.error('Error sending message:', error);
      createToast({ text: 'Error sending request', variant: 'warning' });
    }
  };

  if (isPending) {
    return (
      <div className="w-full flex justify-start">
        <div className="w-full">
          <Skeleton type="form-details" />
        </div>
      </div>
    );
  }

  const handleCancelAction = (resetForm: () => void) => {
    resetForm();
    setButtonsDisabled(true);
  };

  const handleDisconnectAction = () => {
    setShowConfirmationModal(true);
    setShowMoreOption(false);
  };

  const handleDisconnectAccount = async (values: any) => {
    const newAccountState =
      values.status === IntegrationStatus.Enabled ? IntegrationStatus.Disabled : IntegrationStatus.Enabled;

    try {
      await patchIntegration(values.amsCode, values.ams, 'AMS', newAccountState, values.uid);
      createToast({
        text: newAccountState === 'Enabled' ? 'Enabled is successful' : 'Disabled is successful',
        variant: 'success',
      });
      refetch();
      setTimeout(() => {
        setShowConfirmationModal(false);
      }, 1000);
    } catch (error) {
      console.error('Error sending message:', error);
      createToast({ text: 'Error sending request', variant: 'warning' });
      refetch();
    }
  };

  const handleOpenModalManageMapping = () => {
    setOpenModalManageMapping(true);
    setShowMoreOption(false);
  };

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
        {({ values, handleChange, resetForm }) => (
          <Form className="w-full">
            <div className="w-full flex mb-6">
              <div className="w-1/2 flex justify-start">
                <h1 className="text-core-black text-2xl text-left">Integrations</h1>
              </div>

              <div className="w-1/2 flex justify-end gap-2">
                {integrationData?.integration && (
                  <>
                    <div className="w-fit">
                      <Button
                        variant="outline"
                        color="terceary"
                        disabled={buttonsDisabled}
                        onClick={() => handleCancelAction(resetForm)}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="w-fit">
                      <Button variant="fill" color="secondary" type="submit" disabled={buttonsDisabled}>
                        Save
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>

            {!integrationData?.integration ? (
              <>
                <div className="border-2 rounded-[4px] p-6 flex flex-col md:flex-row gap-3">
                  <div className="w-full md:w-[15%] flex justify-start">
                    <img src={cakeIntegration} alt="Cake Integration img" className="w-32 h-32 rounded" />
                  </div>
                  <div className="text-left my-auto w-full md:w-[65%] flex flex-col justify-start">
                    <h1 className="text-core-black font-medium text-2xl">Connect your AMS</h1>
                    <p className="text-gray-700 font-normal text-lg p-0 m-0">
                      Automatically import your book of business and sync it to Cake’s platform.
                    </p>
                  </div>
                  <div className="flex justify-end w-full md:w-[15%]">
                    <div className="w-full">
                      <Button variant="outline" color="primary" onClick={handleUpload}>
                        Connect
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-full mt-12 mb-2">
                  <p className="text-lg text-left">Integrations</p>
                </div>
                <div className="border-2 rounded-[4px] p-6 flex flex-col md:flex-row gap-1">
                  <div className="w-full md:w-[8%] flex justify-start">
                    <img src={hawkSoftLogo} alt="hawksoft sincronation logo" className="w-16 h-16 mt-1" />
                  </div>

                  <div className="w-full md:w-[75%] flex justify-start text-left flex-col">
                    <h3 className="text-core-black text-2xl font-medium">Hawksoft</h3>
                    <p className="p-0 m-0 text-gray-500 text-sm">
                      <span
                        className={`border rounded text-xs py-1 px-2 mr-2 ${
                          integrationData.integration.status === IntegrationStatus.Enabled
                            ? 'text-green-500'
                            : 'text-core-warning'
                        }`}
                      >
                        {integrationData.integration.status === IntegrationStatus.Disabled
                          ? 'Disconnected'
                          : integrationData.integration.status}
                      </span>{' '}
                      {integrationData.integration.last_sync !== null && (
                        <>Last sync {formatLastSync(integrationData.integration.last_sync)}</>
                      )}
                    </p>

                    <div className="w-full flex gap-3 mt-8">
                      <span className="text-core-textNavy text-sm rounded py-1 px-2 bg-[#19497808] gap-2 flex">
                        {gitPull}{' '}
                        {mappedItems.length > 1
                          ? mappedItems.length + ' ' + 'fields'
                          : mappedItems.length + ' ' + 'field'}{' '}
                        mapped
                      </span>

                      {unmappedItems.length > 1 && (
                        <span className="text-core-warning text-sm rounded py-1 px-2 bg-[#9F640208] gap-2 flex">
                          {warningIcon}{' '}
                          {unmappedItems.length > 1
                            ? unmappedItems.length + ' ' + 'issues'
                            : mappedItems.length + ' ' + 'issue'}{' '}
                        </span>
                      )}

                      {unmappedItems.length > 1 && (
                        <span
                          className="text-core-black font-bold uppercase text-sm p-1 cursor-pointer"
                          onClick={handleOpenModalManageMapping}
                        >
                          Review
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-end w-full md:w-[18%]">
                    <div className="w-fit flex gap-2">
                      {/* <Button variant="outline" color="terceary">
                        <span className="flex gap-2 text-sm">
                          <ArrowPathIcon className="my-auto w-4 h-4" /> Refresh
                        </span>
                      </Button> */}

                      <div>
                        <button
                          ref={buttonRef}
                          type="button"
                          className={`border-2  transition ease-in rounded-[4px] h-fit p-1.5 ${
                            showMoreOption ? 'border-blue-500' : 'border-gray-300'
                          }`}
                          onClick={handleOpenMoreOptions}
                        >
                          <span className="flex gap-2 text-sm">
                            <EllipsisHorizontalIcon className="my-auto w-6 h-6" />
                          </span>
                        </button>

                        {showMoreOption && (
                          <div
                            ref={menuRef}
                            className="absolute w-[190px] bg-white rounded-[3px] border-2 border-blue-500 shadow p-2 right-20 mt-2"
                          >
                            <ul className="p-0 m-0 text-left">
                              {mappedItems.length === 0 ||
                                (unmappedItems.length === 0 && (
                                  <li
                                    className="text-xs font-medium rounded px-3 py-2 hover:bg-neutral-50 transition ease-in cursor-pointer"
                                    onClick={handleOpenModalManageMapping}
                                  >
                                    Manage Mapping
                                  </li>
                                ))}

                              <li
                                className="text-xs font-medium rounded px-3 py-2 hover:bg-neutral-50 transition ease-in cursor-pointer"
                                onClick={handleDisconnectAction}
                              >
                                {integrationData?.integration.status === IntegrationStatus.Enabled
                                  ? 'Disconnect'
                                  : 'Connect'}
                              </li>
                              {/* <li className="text-xs text-red-500 font-medium rounded px-3 py-2 hover:bg-neutral-50 transition ease-in cursor-pointer">
                                Remove
                              </li> */}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full my-12">
                  <p className="text-lg text-left">Agency Management System ID</p>

                  <div className="w-1/2 my-2">
                    <Input
                      type="text"
                      labelInput="AMS ID"
                      placeholder="Enter your AMS ID"
                      isRequiredInput={true}
                      name="amsCode"
                      value={values.amsCode}
                      onChange={(e) => {
                        handleChange(e);
                        setButtonsDisabled(false);
                      }}
                    />
                  </div>
                </div>

                {showConfirmationModal && (
                  <ModalConfirmDisconnect
                    onClose={() => closeModal(resetForm)}
                    onDisconnect={handleDisconnectAccount}
                    values={values}
                  />
                )}

                {openModalManageMapping && (
                  <ModalManageMapping integrationData={integrationData} onClose={() => handleCloseModalMapping()} />
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
      {alerts}

      {isModalUploadOpen && (
        <UploadModal isOpen={isModalUploadOpen} onClose={onCloseModalIntegration} startStep="AMS" />
      )}
    </>
  );
};

export default Integrations;
