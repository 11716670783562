import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
import { userStateSelector } from 'src/app/user/UserStoreSelectors';
import cakeLogoGold from '../../../../styles/assets/logos/cake-logo-gold.svg';
import cakeLogoCGold from '../../../../styles/assets/logos/cake-logo-c-gold.svg';
import { ReactComponent as InboxIconOutline } from '../../../../styles/assets/icons/outline/inboxOutline.svg';
import { ReactComponent as InboxIconSolid } from '../../../../styles/assets/icons/solid/inboxSolid.svg';
import { ReactComponent as UserIconOutline } from '../../../../styles/assets/icons/outline/userIconOutline.svg';
import { ReactComponent as LeftArrowMini } from '../../../../styles/assets/icons/mini/leftArrow.svg';
import { ReactComponent as RightArrowMini } from '../../../../styles/assets/icons/mini/rightArrow.svg';
import { ReactComponent as HelpOutlineIcon } from '../../../../styles/assets/icons/outline/helpOutline.svg';
import { ReactComponent as UserIconCircle } from '../../../../styles/assets/icons/outline/userCircle.svg';
import { ReactComponent as UserIconCircleSolid } from '../../../../styles/assets/icons/solid/userCircle.svg';
import { ReactComponent as BuyerProfileCircle } from '../../../../styles/assets/icons/outline/plus-circle.svg';
import { ReactComponent as BuyerProfileCircleActive } from '../../../../styles/assets/icons/outline/plus-circle-active.svg';
import routes from '../../utils/routes';
import { useOnboardingStore } from 'src/app/store/store';
import { useGetSubscription, useGetUser } from 'src/hooks';
import { Button } from '../Buttons/Button';
import posthog from 'posthog-js';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import useAlert from '../Toast/useAlert';
import { ChartPieIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { BuildingLibraryIcon } from '@heroicons/react/24/outline';
import {
  BuildingLibraryIcon as BuildingLibraryIconActive,
  ChartPieIcon as ChartPieIconSolid,
  Squares2X2Icon as Squares2X2IconActive,
} from '@heroicons/react/24/solid';
interface MenuItemProps {
  active: boolean;
  icon: React.ReactNode;
  label: string;
  activeIcon: React.ReactNode;
  onClick: () => void;
}

const SideBarNav: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { userInfo } = useOnboardingStore();
  const { data: subscriptionResponse, isPending: subscriptionPending, error: subscriptionError } = useGetSubscription();
  const [subscriptionName, setSubscriptionName] = useState<string>('');
  const { data: buyerProfileResponse, refetch } = useGetBuyerProfile();
  const { isSuccess } = usePatchBuyerProfile();
  const { data: userResponse } = useGetUser();

  const { userProfile } = useAppSelector(userStateSelector);
  const [containerWidth, setContainerWidth] = useState<'w-64' | 'w-20'>('w-64');
  const [isButtonExpanded, setIsButtonExpanded] = useState<boolean>(true);
  const iconSizeClass = isButtonExpanded ? 'sm:w-4 sm:h-4 w-7 h-7' : 'sm:w-5 sm:h-5 w-7 h-7';
  const { alerts } = useAlert();

  const handleNavigationClick = (path: string): void => {
    history.push(path);
  };

  const handleButtonClick = () => {
    setContainerWidth((prevWidth) => (prevWidth === 'w-64' ? 'w-20' : 'w-64'));
    setIsButtonExpanded(!isButtonExpanded);
  };

  const getArrowIcon = () => {
    return isButtonExpanded ? rigthArrowIcon : leftArrowIcon;
  };

  // icons
  const sliceIcon = <BuildingLibraryIcon className={`my-auto group-hover:text-yellow-500 ${iconSizeClass}`} />;
  const sliceIconActive = <BuildingLibraryIconActive className={`my-auto text-yellow-500 ${iconSizeClass}`} />;
  const buyerProfileIcon = <Squares2X2Icon className={`my-auto group-hover:text-yellow-500 ${iconSizeClass}`} />;
  const buyerProfileIconActive = <Squares2X2IconActive className={`my-auto text-yellow-500 ${iconSizeClass}`} />;
  const InboxIcon = <InboxIconOutline className={`my-auto group-hover:text-yellow-500 ${iconSizeClass}`} />;
  const InboxIconActive = <InboxIconSolid className={`my-auto text-yellow-500 ${iconSizeClass}`} />;
  const BookManagementIcon = <ChartPieIcon className={`my-auto group-hover:text-yellow-500 ${iconSizeClass}`} />;
  const BookManagementIconActive = <ChartPieIconSolid className={`my-auto text-yellow-500 ${iconSizeClass}`} />;
  const userIcon = <UserIconCircle className={`my-auto group-hover:text-yellow-500 ${iconSizeClass}`} />;
  const userIconActive = <UserIconCircleSolid className={`my-auto text-yellow-500 ${iconSizeClass}`} />;
  const buyerProfileCircle = <BuyerProfileCircle className={`my-auto group-hover:text-yellow-500 ${iconSizeClass}`} />;
  const buyerProfileCircleActive = <BuyerProfileCircleActive className={`my-auto text-yellow-500 ${iconSizeClass}`} />;
  const leftArrowIcon = <LeftArrowMini className="w-4 h-4 rounded" />;
  const rigthArrowIcon = <RightArrowMini className="w-5 h-5" />;

  useEffect(() => {
    if (userInfo?.userProfile && !userInfo?.userProfile.onboardingComplete) {
      window.location.href = '/onboarding/role';
    }
  }, [userInfo]);

  useEffect(() => {
    if (!subscriptionPending && !subscriptionError && subscriptionResponse) {
      setSubscriptionName(subscriptionResponse.userSubscription);
    }
  }, [subscriptionPending, subscriptionError, subscriptionResponse]);

  const handleGotoPricingPage = () => {
    history.push(`/account/pricing`);
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  // menu list items
  const menuItems = [
    {
      path: routes.slices,
      pathChild: routes.sliceDetails,
      icon: sliceIcon,
      activeIcon: sliceIconActive,
      label: 'Marketplace',
    },
    {
      path: routes.buyersDatabase,
      icon: buyerProfileIcon,
      activeIcon: buyerProfileIconActive,
      label: 'Buyer Database',
    },
    {
      path: routes.bookManagement,
      icon: BookManagementIcon,
      activeIcon: BookManagementIconActive,
      label: 'Book Management',
    },
    {
      path: routes.buyerProfile,
      icon: buyerProfileCircle,
      activeIcon: buyerProfileCircleActive,
      label: 'Buyer Profile',
    },
    {
      path: routes.inbox,
      icon: InboxIcon,
      activeIcon: InboxIconActive,
      label: 'Inbox',
    },
  ];

  const MenuItem: React.FC<MenuItemProps> = ({ active, icon, label, activeIcon, onClick }) => (
    <li
      className={`hover:bg-core-pointer whitespace-nowrap group cursor-pointer transition ease-in w-full rounded py-1.5 px-2 flex gap-2 my-auto text-[#AAAAAA] mb-2 ${
        active ? 'bg-core-pointer' : ''
      }`}
      onClick={onClick}
    >
      {active ? activeIcon : icon}
      {isButtonExpanded ? (
        <span className={`group-hover:text-white ${active ? 'text-white' : ''}`}>{label}</span>
      ) : null}
    </li>
  );

  const rendeMenuItems = () => {
    return menuItems
      .filter((item) => !!item)
      .map((item) => {
        if (typeof item === 'object' && 'path' in item && 'icon' in item && 'activeIcon' in item && 'label' in item) {
          const { path, pathChild, icon, activeIcon, label } = item;
          return (
            <MenuItem
              key={path}
              active={location.pathname === path || location.pathname === pathChild}
              icon={icon}
              activeIcon={activeIcon}
              label={label}
              onClick={() => handleNavigationClick(path)}
            />
          );
        }
        return null;
      });
  };

  return (
    <div className="h-full w-fit sticky top-0 z-50">
      {/* no mobile side nav */}
      <div
        className={`hidden sm:relative sm:block h-20 sm:min-h-screen ${containerWidth} p-2 transition-width ease-in-out duration-300`}
      >
        <div
          className={`bg-core-black h-16 sm:min-h-[98vh] w-full rounded ${isButtonExpanded ? 'p-6' : 'py-6 px-3.5'}`}
        >
          <div className="flex w-full mb-10">
            <div className={`flex ${isButtonExpanded ? 'w-1/2 justify-start' : 'justify-center'}`}>
              <img
                className={`h-auto flex justify-start ${isButtonExpanded ? 'w-14' : '!w-62 !h-62 ml-2 block'}`}
                src={isButtonExpanded ? cakeLogoGold : cakeLogoCGold}
                onClick={(): void => {
                  handleNavigationClick(routes.slices);
                }}
              />
            </div>

            <div className="flex justify-end w-1/2">
              <button
                className={`text-white my-auto ${
                  isButtonExpanded ? 'block' : 'relative -mr-6 bg-core-black border-0 rounded-full p-1'
                }`}
                onClick={handleButtonClick}
              >
                {getArrowIcon()}
              </button>
            </div>
          </div>

          <div className="w-full flex flex-col">
            <ul className="!pl-0 text-[16px]">{rendeMenuItems()}</ul>
          </div>

          {/* CTA */}
          {isButtonExpanded &&
          posthog.isFeatureEnabled('buyer-profile-nav-test') &&
          !buyerProfileResponse?.buyerProfile.profilePublished &&
          subscriptionName !== 'Pro' &&
          !userResponse?.userProfile.hasActiveListing ? (
            <div className="rounded-[4px] py-3 px-2 border-1 border-core-grey hover:border-core-yellow transition ease-in">
              <p className="text-xs font-medium text-center text-white">
                Want sellers to discover your profile and connect? Publish your profile.
              </p>
              <Button variant="fill" color="primary" onClick={handleGotoPricingPage}>
                Publish
              </Button>
            </div>
          ) : null}

          <div className="bottom-8 absolute w-fit bg-core-black">
            <ul className="space-y-4 !pl-0 text-[14px]">
              <a
                href="https://www.haveyourcake.com/contact-cake"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full flex no-underline"
              >
                <li className="group cursor-pointer transition ease-in w-full py-1.5 px-2 flex gap-2 my-auto text-[#AAAAAA] hover:text-white">
                  <HelpOutlineIcon
                    className={`my-auto ${isButtonExpanded ? 'sm:w-4 sm:h-4 w-7 h-7' : 'sm:w-5 sm:h-5'}`}
                  />
                  {isButtonExpanded ? <span>Need Help?</span> : null}
                </li>
              </a>
            </ul>

            <div
              className={`my-auto rounded py-1.5 cursor-pointer px-2 bg-[#181818] flex gap-2 w-fit border-1 ${
                location.pathname === routes.account ? 'border-core-yellow' : 'border-core-black'
              }`}
              onClick={(): void => handleNavigationClick(routes.account)}
            >
              <div className="text-white my-auto">
                <UserIconOutline
                  className={`${location.pathname === routes.account ? 'text-core-yellow' : ''} ${
                    isButtonExpanded ? 'p-2 w-8 h-8 ' : 'p-0.5 w-5 h-5'
                  }`}
                />
              </div>

              {isButtonExpanded ? (
                <div className="text-[13px] flex flex-col text-start -space-y-1">
                  <span className="text-white font-bold truncate max-w-[135px]">{userProfile.email}</span>
                  {userInfo?.userProfile.hasActiveListing ? (
                    <span className="text-[#AAA] font-normal">Seller</span>
                  ) : (
                    <span className="text-[#AAA] font-normal">{subscriptionName || 'Starter'}</span>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* mobile menu */}
      <div className="p-2 fixed w-full bottom-0 block sm:hidden">
        <div className="bg-core-black p-3 w-full rounded flex">
          <ul className="flex gap-3 text-[#AAAAAA] w-full my-auto mx-auto !pl-0">
            <li
              className="w-1/5 mx-auto"
              onClick={(): void => {
                handleNavigationClick(routes.slices);
              }}
            >
              <div
                className={`w-fit p-1 rounded mx-auto ${location.pathname === routes.slices ? 'bg-core-pointer' : ''}`}
              >
                {location.pathname === routes.slices ? sliceIconActive : sliceIcon}
              </div>
            </li>

            <li
              className="w-1/5 mx-auto"
              onClick={(): void => {
                handleNavigationClick(routes.buyersDatabase);
              }}
            >
              <div
                className={`w-fit p-1 rounded mx-auto ${
                  location.pathname === routes.buyersDatabase ? 'bg-core-pointer' : ''
                }`}
              >
                {location.pathname === routes.buyersDatabase ? buyerProfileIconActive : buyerProfileIcon}
              </div>
            </li>

            <li
              className="w-1/5"
              onClick={(): void => {
                handleNavigationClick(routes.bookManagement);
              }}
            >
              <div
                className={`w-fit p-1 rounded mx-auto ${
                  location.pathname === routes.bookManagement ? 'bg-core-pointer' : ''
                }`}
              >
                {location.pathname === routes.bookManagement ? BookManagementIconActive : BookManagementIcon}
              </div>
            </li>

            <li
              className="w-1/5"
              onClick={(): void => {
                handleNavigationClick(routes.inbox);
              }}
            >
              <div
                className={`w-fit p-1 rounded mx-auto ${location.pathname === routes.inbox ? 'bg-core-pointer' : ''}`}
              >
                {location.pathname === routes.inbox ? InboxIconActive : InboxIcon}
              </div>
            </li>

            <li
              className="w-1/5"
              onClick={(): void => {
                handleNavigationClick(routes.account);
              }}
            >
              <div
                className={`w-fit p-1 rounded mx-auto ${location.pathname === routes.account ? 'bg-core-pointer' : ''}`}
              >
                {location.pathname === routes.account ? userIconActive : userIcon}
              </div>
            </li>
          </ul>
        </div>
      </div>
      {alerts}
    </div>
  );
};

export default SideBarNav;
