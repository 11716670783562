import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

interface TopCarriersProps {
  totalCarriersData: {
    group: {
      policies: {
        carrier: string;
        total_premium: number;
      }[];
    };
  };
}

const TopCarriers: React.FC<TopCarriersProps> = ({ totalCarriersData }) => {
  const [topOpen, setTopOpen] = useState(false);

  const handleOpenTop = () => {
    setTopOpen(!topOpen);
  };

  const topCarriersList = totalCarriersData.group.policies
    .reduce((acc, policy) => {
      const existingCarrier = acc.find((item) => item.carrier === policy.carrier);

      if (existingCarrier) {
        existingCarrier.total_premium += policy.total_premium;
        existingCarrier.num_policies++;
      } else {
        acc.push({
          carrier: policy.carrier,
          total_premium: policy.total_premium,
          num_policies: 1,
        });
      }

      return acc;
    }, [] as { carrier: string; total_premium: number; num_policies: number }[])
    .sort((a, b) => b.num_policies - a.num_policies)
    .slice(0, 5)
    .map((policy) => {
      const formattedPremium = policy.total_premium.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });

      return (
        <tr key={policy.carrier}>
          <td className="py-1 font-medium text-sm text-core-black whitespace-nowrap">
            <div className="truncate max-w-[200px]">{policy.carrier}</div>
          </td>
          <td className="py-1 px-3 font-medium text-sm text-core-black whitespace-nowrap">{formattedPremium}</td>
          <td className="py-1 px-3 font-medium text-sm text-core-black whitespace-nowrap">{policy.num_policies}</td>
        </tr>
      );
    });

  return (
    <div className="w-full bg-core-lightGrey rounded-lg p-6">
      <div className="w-full flex">
        <div className="text-left w-1/2 flex flex-col">
          <h1 className="p-0 m-0 font-medium text-core-black text-lg">Top Carriers</h1>
        </div>

        <div className="w-1/2 flex justify-end">
          <button className="bg-white rounded-md py-1 px-1.5 hover:shadow" onClick={handleOpenTop}>
            {topOpen ? <ChevronUpIcon className="w-4 h-4 my-auto" /> : <ChevronDownIcon className="w-4 h-4 my-auto" />}
          </button>
        </div>
      </div>

      {topOpen && (
        <div className="w-full mt-2">
          <div className="relative overflow-x-auto pb-3">
            <table className="w-full text-sm text-left">
              <thead className="text-xs text-gray-600 uppercase font-bold whitespace-nowrap">
                <tr>
                  <th scope="col" className="py-3">
                    Name
                  </th>
                  <th scope="col" className="py-3 px-3">
                    Annual premium
                  </th>
                  <th scope="col" className="py-3 px-3">
                    Policies
                  </th>
                </tr>
              </thead>
              <tbody>{topCarriersList}</tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopCarriers;
