import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { appStore } from './app/appStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(
  'CompanyName=Cosmic Software Solutions,LicensedApplication=Cake App,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021763,ExpiryDate=1_November_2022_[v2]_MTY2NzI2MDgwMDAwMA==5c72ef74520de3bd77ea3d3de33385a0'
);
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={appStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
