import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { postIntegration } from 'src/api/integrations';
import { Button } from 'src/app/shared/components/Buttons/Button';
import Input from 'src/app/shared/components/Inputs/Input';
import Select from 'src/app/shared/components/Inputs/Select';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { amsValues } from 'src/constants/amsValues';
import cakeLogo from 'src/styles/assets/logos/cakeIntegrationLogo.svg';
import hawkSoftLogo from 'src/styles/assets/logos/hawksoftLogo.svg';
import { useAppSelector } from 'src/app/hooks';
import { userStateSelector } from 'src/app/user/UserStoreSelectors';

interface amsProps {
  onBack: () => void;
  onClose: () => void;
  external?: boolean;
}

interface Step {
  id: number;
  renderContent: () => React.ReactNode;
}

const AmsIntegration = (props: amsProps): React.ReactElement => {
  const { onBack, onClose } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const transformedAmsValues = amsValues.map((value) => ({ value, label: value }));
  const [syncClicked, setSyncClicked] = useState(false);
  const [selectedAms, setSelectedAms] = useState('');
  const [amsId, setAmsId] = useState('');
  const { alerts, createToast } = useAlert();
  const initialValues = {
    ams: '',
    amsCode: '',
  };
  const { userProfile } = useAppSelector(userStateSelector);

  const [stepsData, setStepsData] = useState<Step[]>([
    {
      id: 1,
      renderContent: () => (
        <div className="mt-20 mb-56 w-full">
          <div className="w-full px-20">
            <h1 className="text-core-black text-3xl mb-6 font-medium text-center">
              What agency management system do you use?
            </h1>
          </div>

          <div className="w-full px-36">
            <Select
              options={transformedAmsValues}
              name="ams"
              positionLast={false}
              labelInput="Agency Management System"
              placeholderOption="Agency Management System"
              value=""
              onChange={(e) => {
                setSelectedAms(e.target.value);
                setButtonsDisabled(false);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      id: 2,
      renderContent: () => (
        <>
          <div className="w-full px-20 my-24">
            <div className="w-fit flex mx-auto mb-3">
              <div>
                <img src={cakeLogo} alt="cake sincronitation logo" className="w-16 h-auto m-auto" />
              </div>
              <div className="my-auto mx-2 flex">
                <div className="border-t-2 border-dashed w-10 mt-[8.7px] -mr-2"></div>
                <div>
                  <ChevronRightIcon className="text-gray-300 my-auto h-5 w-5" />
                </div>
              </div>
              <div>
                <img src={hawkSoftLogo} alt="hawksoft sincronation logo" className="w-16 h-auto m-auto" />
              </div>
            </div>
            <h1 className="text-core-black text-3xl mb-6 font-medium text-center">We integrate with Hawksoft!</h1>
            <p className="text-gray-700 text-base text-left font-medium p-0 mb-8">
              To integrate with Hawksoft, follow these steps:
            </p>

            <p className="text-gray-700 text-base font-medium p-0 text-left  mb-2">1. Access your Hawksoft account.</p>
            <p className="text-gray-700 text-base font-medium p-0 text-left  mb-2">
              2. Click the Setup Menu (gear icon) at the top right corner.
            </p>
            <p className="text-gray-700 text-base font-medium p-0 text-left mb-2">3. Choose 'Marketplace'.</p>
            <p className="text-gray-700 text-base font-medium p-0 text-left mb-2">
              4. Locate the Cake listing: Cake: Enables data sharing from Hawksoft to Cake.
            </p>
            <p className="text-gray-700 text-base font-medium p-0 text-left mb-2">
              5. Check the boxes beside the listing to agree to their Terms and Conditions.
            </p>
            <p className="text-gray-700 text-base font-medium p-0 text-left mb-10">6. Click 'Share Data'.</p>

            <p className="text-gray-700 text-base font-medium p-0 text-left mb-2">
              Additionally, while in Hawksoft, obtain your Agency Account ID for use in Cake.
            </p>
            {/* <p className="text-gray-700 text-base font-medium p-0 text-center mb-8">
              Let’s link accounts. To do so, you will need to login to your Hawksoft account, enable the Cake
              application and then enter in your Cake client ID. Once you’ve completed those steps, hit continue.
            </p>
            <div className="w-fit my-4 mx-auto">
              <Button
                variant="fill"
                color="secondary"
                onClick={() => {
                  setButtonsDisabled(false);
                  window.open('https://agent.hawksoft.cloud/signin', '_blank');
                }}
              >
                Take me to Hawksoft
              </Button>
            </div> */}
          </div>
        </>
      ),
    },
    {
      id: 3,
      renderContent: () => (
        <div className="my-24">
          <h1 className="text-core-black text-3xl mb-6 font-medium text-center">Enter in your AMS ID</h1>
          <div className="w-full mt-4 px-20">
            <p className="text-gray-700 text-base font-medium p-0 text-center mb-2">
              You can locate this information within Hawksoft either on the Login page or by navigating to the Setup
              Menu and selecting <strong>"License Info."</strong>
            </p>
          </div>

          <div className="w-1/2 mx-auto my-4">
            <Input
              type="text"
              labelInput="AMS ID"
              placeholder="Enter your AMS ID"
              isRequiredInput={true}
              name="amsCode"
              onChange={(e) => {
                setAmsId(e.target.value);
                setButtonsDisabled(false);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      id: 4,
      renderContent: () => (
        <div className="my-24">
          <div className="w-fit flex mx-auto mb-3">
            <div className="z-50">
              <img id="cake-logo" src={cakeLogo} alt="cake sincronitation logo" className="w-17 h-auto m-auto" />
            </div>
            <div className="my-auto mx-2 flex">
              <div>
                <ChevronLeftIcon className="text-core-black my-auto h-5 w-5" />
              </div>
              <div className="border-t-2 border-core-black w-10 mt-[8.9px] -mx-3"></div>
              <div>
                <ChevronRightIcon className="text-core-black my-auto h-5 w-5" />
              </div>
            </div>
            <div className="z-10">
              <img
                id="hawksoft-logo"
                src={hawkSoftLogo}
                alt="hawksoft sincronation logo"
                className="w-16 h-auto m-auto"
              />
            </div>
          </div>

          <div className="w-full px-44">
            <h1 className="text-core-black text-3xl mb-6 font-medium text-center">
              {syncClicked ? 'Sync Initiated!' : 'Ready to sync your book?'}
            </h1>
            <p className="text-gray-700 text-base font-medium p-0 text-center mb-8">
              {syncClicked
                ? 'Please wait 24 hours for the sync to complete. We will let you know when it’s ready.'
                : 'Now that you’ve hopefully linked your Hawksoft and Cake accounts, it’s time to sync your book data. Click the button below to initiate the process and then hit finish!'}
            </p>
            {!syncClicked && (
              <div className="w-fit my-4 mx-auto">
                <Button
                  variant="fill"
                  color="secondary"
                  onClick={() => {
                    setSyncClicked(true);
                    setButtonsDisabled(false);
                  }}
                >
                  Sync accounts
                </Button>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      id: 6,
      renderContent: () => (
        <div className="overflow-y-auto h-[900px]">
          <div id="jotform-container" className="w-full px-20 py-0 min-h-[1900px]"></div>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://form.jotform.com/jsform/240675859063062?user=${encodeURIComponent(userProfile.email)}`;
    script.async = true;
    const jotformContainer = document.getElementById('jotform-container');
    if (jotformContainer) {
      jotformContainer.appendChild(script);
    }

    return () => {
      if (jotformContainer && jotformContainer.contains(script)) {
        jotformContainer.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (currentStep === 2) {
      setButtonsDisabled(false);
    }
  });

  useEffect(() => {
    const cakeLogoElement = document.getElementById('cake-logo');
    const hawkSoftLogoElement = document.getElementById('hawksoft-logo');
    if (syncClicked && cakeLogoElement && hawkSoftLogoElement) {
      cakeLogoElement.classList.add(
        'transition-transform',
        'duration-500',
        'transform',
        'translate-x-11',
        'border-2',
        'bg-white',
        'border-white',
        'rounded-full',
        'p-0'
      );
      hawkSoftLogoElement.classList.add('transition-transform', 'duration-500', 'transform', '-translate-x-10');
    } else {
      cakeLogoElement?.classList.remove(
        'transition-transform',
        'duration-500',
        'transform',
        'translate-x-11',
        'border-2',
        'bg-white',
        'border-white',
        'rounded-full',
        'p-0'
      );
      hawkSoftLogoElement?.classList.remove('transition-transform', 'duration-500', 'transform', '-translate-x-10');
    }
  }, [syncClicked]);

  const handlePrevStep = (step: number) => {
    if (step === 1) {
      onBack();
    } else if (step === 5) {
      setStepsData((prevStepsData) => prevStepsData.filter((step) => step.id !== 5));
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFinishIntegration = async () => {
    onSubmit();
  };

  const handleNextStep = () => {
    setButtonsDisabled(true);
    if (selectedAms.toLowerCase() === 'hawksoft') {
      setCurrentStep(currentStep + 1);
    } else {
      const newStep = {
        id: 5,
        renderContent: () => (
          <div className="my-20">
            <div className="w-full px-24">
              <p className="text-center text-xs text-gray-600 font-bold mx-0 p-0 mb-2 uppercase">Upload your book</p>
              <div className="w-full">
                <h1 className="text-core-black text-lg md:text-4xl font-medium text-center">
                  We don’t integrate directly with your AMS, but that’s ok!
                </h1>
              </div>

              <div className="w-full mt-4 px-20">
                <p className="text-center text-sm text-core-black mx-0 p-0 mb-8">
                  We don’t currently support AMS integration with {selectedAms}, but Cake can use files from your system
                  to build your book of business for you.
                </p>
              </div>

              <div className="w-full mb-4 space-y-3">
                <div className="w-2/5 mx-auto">
                  <Button
                    variant="fill"
                    color="secondary"
                    onClick={() => {
                      setCurrentStep(6);
                      setButtonsDisabled(false);
                    }}
                  >
                    Upload my files
                  </Button>
                </div>

                <div className="w-2/5 mx-auto">
                  <Button variant="outline" color="primary" onClick={onClose}>
                    Return to My Book
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ),
      };
      setStepsData((prevStepsData) => [...prevStepsData.slice(0, 2), newStep, ...prevStepsData.slice(2)]);
      setCurrentStep(5);
    }
  };

  const onSubmit = async () => {
    try {
      const selectedAmsCapitalized = selectedAms.charAt(0).toUpperCase() + selectedAms.slice(1).toLowerCase();
      await postIntegration('AMS', selectedAmsCapitalized, amsId, 'Enabled');

      createToast({ text: 'Request sent successfully!', variant: 'success' });
      setTimeout(() => {
        onClose();
      }, 5000);
    } catch (error) {
      console.error('Error sending message:', error);
      createToast({ text: 'Error sending request', variant: 'warning' });
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
        <Form className="w-full">
          <div className="w-full">
            {stepsData.map((step) => (
              <div key={step.id} style={{ display: step.id === currentStep ? 'block' : 'none' }}>
                {step.renderContent()}

                {step.id > 0 && step.id !== 5 && step.id !== 6 && (
                  <div className="w-full border-t p-6 flex">
                    <div className="w-1/2 flex justify-start">
                      <button
                        onClick={() => handlePrevStep(step.id)}
                        className="flex py-2 px-4 w-fit text-core-black font-bold uppercase gap-2 hover:bg-neutral-50 transition ease-in rounded"
                      >
                        <ArrowLeftIcon className="h-3 w-3 my-auto stroke-black" /> Back
                      </button>
                    </div>

                    <div className="w-full flex justify-end">
                      <div className="w-fit">
                        <Button
                          variant="fill"
                          color="primary"
                          onClick={step.id === 4 ? handleFinishIntegration : handleNextStep}
                          disabled={buttonsDisabled}
                        >
                          {step.id === 4 ? 'Finish' : 'Continue'}
                        </Button>
                      </div>
                      {alerts}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default AmsIntegration;
