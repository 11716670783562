import React, { useState } from 'react';
import { ArrowRightIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ReactComponent as ExclamationSplashIcon } from '../../../../styles/assets/icons/solid/exclamationIconSolid.svg';

interface NotificationsProps {
  text: string;
  type: 'info' | 'warning' | 'success';
  action?: 'review';
}

const Notifications: React.FC<NotificationsProps> = ({ text, type, action }) => {
  const [visible, setVisible] = useState(true);

  const handleOnClose = () => {
    setVisible(false);
  };

  const getNotificationClassName = () => {
    let className = 'transition-opacity duration-200 w-full p-3 flex shadow border-l-4 ';
    if (visible) {
      className += 'opacity-100 ';
    } else {
      className += 'opacity-0 ';
    }

    if (type === 'info') {
      className += 'border-l-core-yellow bg-white ';
    } else if (type === 'warning') {
      className += 'border-l-core-warning !bg-[#FFF6E8] ';
    } else if (type === 'success') {
      className += 'border-l-core-green bg-white ';
    }

    className += ' rounded-[4px] mb-4 relative';
    return className;
  };

  return (
    <div className={`transition-opacity duration-500 ${visible ? 'h-fit' : 'h-0'}`}>
      <div className={getNotificationClassName()}>
        <div className="w-11/12 flex gap-2 relative items-center">
          {type === 'info' && <ExclamationSplashIcon className="text-core-black h-5 w-5 my-auto" />}
          {type === 'warning' && <ExclamationTriangleIcon className="text-core-black h-5 w-5 my-auto" />}
          <p className="text-core-black p-0 m-0 text-left text-sm">
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </p>
        </div>
        <div className="w-1/12 flex justify-end">
          {action === null && (
            <XMarkIcon
              className="w-5 h-5 text-core-black cursor-pointer hover:text-core-grey transition ease-in"
              onClick={handleOnClose}
            />
          )}

          {action === 'review' && (
            <button
              className="text-core-black uppercase text-xs font-bold flex items-center gap-2"
              onClick={() => (window.location.href = '/account#integrations')}
            >
              Review
              <ArrowRightIcon className="w-3 h-3 my-auto" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
